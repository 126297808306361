import { BUCKET_URL } from "const"
import { useMemo } from "react"

export function AssetImg({ symbol, origin, size = 'md' }) {

  const sizeStyle = { 'sm': '1.5rem', 'md': '2rem', 'lg': '3rem' }
  const url = useMemo(() => {
    const urlBase = `${BUCKET_URL}/assets`
    switch (origin) {
      case 1:
        return `${urlBase}/origins/origem-valor-data.svg`
      case 2:
        return `${urlBase}/symbols/${symbol.substring(0, 4)}.svg`
      case 3:
        return `${urlBase}/origins/origem-b3.svg`
      case 9:
        return `${urlBase}/origins/origem-ibge.svg`
      case 1058:
        return `${urlBase}/origins/origem-ambima.svg`
      case 13118:
        return `${urlBase}/origins/origem-fgv.svg`
      case 15157:
      case 15158:
      case 59230:
        return `${urlBase}/origins/origem-cme-group.svg`
      case 15153:
      case 17195:
        return `${urlBase}/origins/origem-nasdaq.svg`
      case 15160:
      case 57201:
        return `${urlBase}/origins/origem-nyse.svg`
      default:
        return `${urlBase}/icone-generico.svg`

    }
  }, [symbol, origin])

  const imageFallback = (event) => {
    const urlBase = `${BUCKET_URL}/assets`
    event.currentTarget.src = (origin === 2 || origin === 3) 
      ? `${urlBase}/origins/origem-b3.svg`
      : `${urlBase}/icone-generico.svg`
  }

  return (
    <div 
      className="overflow-hidden rounded"
      style={{ width: sizeStyle[size], height: sizeStyle[size]}}
    >
      <img className="block rounded-sm" src={url} onError={imageFallback} alt={symbol} />
    </div>
  )

} 

