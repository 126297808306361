import { createRef, useContext, useState } from "react"
import WidgetContextualMenu from "shared/WidgetContextualMenu"
import { MdClose, MdClear, MdOutlineFilterAlt } from "react-icons/md"
import { HiOutlineCalendar } from "react-icons/hi2"
import { LoadingBox } from "shared/Loading"
import { dateStringToLocaleDateString, dateToLocaleDateString } from "helpers/dateHelper"
import Modal from "react-modal"
import React from 'react'
import useClickout from "hooks/useClickout"
import Calendar from 'react-calendar'
import RecommendationContext, { RecommendationContextProvider } from "context/RecommendationContext"
import 'assets/styles/Calendar.css'
import { LAYOUT_BREAKPOINT_WIDTH } from "const"
import useWindowSize from "hooks/useWindowSize"
import CompanyAutocomplete from "shared/CompanyAutocomplete"
import { useLocalParams } from "hooks/useLocalParams"
import { useMemo } from "react"
import { SimpleTooltip } from "shared/HelpTooltip"
import InfinityScrollContainer from "shared/InfinityScrollContainer"
import { WidgetTitle, WidgetLinkTitle } from "shared/WidgetTitle"
import CompaniesList from "./modals/CompaniesList"
import { useCustomId } from "hooks/useCustomId"
import useTitle from "hooks/useTitle"
import DeniedPermissionWidget from "shared/DeniedPermissionWidget"
import iconLiberarAcesso from 'assets/icons/recommendation/bt-liberar-acesso.svg'
import AccessInterceptorContext from "context/AccessInterceptorContext"

export function RecommendationWidget(props) {
  const id = props.id
  const [showFullScreen, setShowFullScreen] = useState(false)
  const { width } = useWindowSize()
  const widgetSize = width >= LAYOUT_BREAKPOINT_WIDTH ? props.desktop.w : 1
  const isIOS = /iPhone|iPad|iPod/.test(window.navigator.userAgent)

  return (
    <RecommendationContextProvider widgetProps={props}>
      <div className="bg-white h-full rounded flex flex-col">
        <div className="pt-4 px-4 drag-handle">
          <WidgetContextualMenu removeItem={() => props.removeItem(id)} />
          <WidgetLinkTitle title="Recomendações e Análises" route="/recomendacoes-e-analises" />
        </div>
        <DeniedPermissionWidget widgetSize={widgetSize} widgetType="Recommendation">
          {widgetSize === 1 ? (
            <RecomendationList showPopup={true} onShowPopup={setShowFullScreen} isIOS={isIOS} />
          ) : (
            <div className={`flex-grow overflow-hidden relative grid gap-4 ${widgetSize === 2 ? 'grid-cols-2' : 'grid-cols-[1fr_2fr]'}`}>
              <RecomendationList />
              <SelectedRecomendation onShowPopup={setShowFullScreen} />
            </div>
          )
          }
          {showFullScreen && <SelectedRecomendationModal onClose={() => setShowFullScreen(false)} isIOS={isIOS} />}
        </DeniedPermissionWidget>
      </div>
    </RecommendationContextProvider>
  )
}

export function RecommendationPage() {
  useTitle('Recomendações e Análises')
  const [showFullScreen, setShowFullScreen] = useState(false)
  const { width } = useWindowSize()
  const params = useMemo(() => ({
    filters: { company: null, customPeriod: null, period: 'W' },
  }), [])
  const localParams = useLocalParams('RECOMMENDATION_PAGE', params)

  return (
    <RecommendationContextProvider widgetProps={localParams}>
      <div className="bg-white h-full rounded flex flex-col">
        <div className="pt-4 px-4">
          <WidgetTitle title="Recomendações e Análises" />
        </div>
        {
          width < LAYOUT_BREAKPOINT_WIDTH ? (
            <RecomendationList showPopup={true} onShowPopup={setShowFullScreen} />
          ) : (
            <div className={`flex-grow overflow-hidden relative grid gap-4 grid-cols-[1fr_2fr]`}>
              <RecomendationList />
              <SelectedRecomendation onShowPopup={setShowFullScreen} />
            </div>
          )
        }
        {showFullScreen && <SelectedRecomendationModal onClose={() => setShowFullScreen(false)} />}
      </div>
    </RecommendationContextProvider>
  )
}

function RecomendationList({ showPopup, onShowPopup, isIOS }) {
  const [modalParams, setModalParams] = useState({ open: null, item: null })
  const [showFilter, setShowFilter] = useState(false)
  const { list, selectedItem, filterValue, setSelectedItem, selectCompany, updatePeriod, loadMore } = useContext(RecommendationContext)
  const calendarId = useCustomId('calendarBtn')
  const { setIsAccessBlocked } = useContext(AccessInterceptorContext)

  function selectItems(item) {
    !item.permissioned && setIsAccessBlocked(true)
    isIOS && window.open(item.caminhoArquivo, '_blank')
    setSelectedItem(item)
    if (showPopup && onShowPopup)
      onShowPopup(true)
  }

  const dropdownRef = createRef()
  const dropdownTriggerRef = createRef()
  useClickout([dropdownTriggerRef, dropdownRef], clickout => {
    if (clickout) setShowFilter(false)
  })

  return (
    <>
      <div className="space-y-3 flex-col h-full flex pb-4">
        <div>
          <div className="flex items-center space-x-3 px-4 pt-4">
            <CompanyAutocomplete customParentClass="flex-grow" setCompany={value => selectCompany(value)} placeholder="Filtrar por empresa" customClass="rounded-lg bg-gray-200 p-2 text-sm w-full text-gray-600" icon={<MdOutlineFilterAlt />} />
            <div className="relative">
              <button ref={dropdownTriggerRef} onClick={() => setShowFilter(true)} id={calendarId} className={`text-base text-indigo-900 font-black ${showFilter && "text-primary gray-600 bg-[#EEECF5] rounded-full"} hover:text-gray-600 hover:rounded-full hover:bg-[#EEECF5] p-2`}>
                <HiOutlineCalendar />
              </button>
              <SimpleTooltip value="Filtre por data" anchor={'#' + calendarId} place="top" />
              <CalendarFilter ref={dropdownRef} closeFilter={() => setShowFilter(false)} show={showFilter} onFilterSelected={updatePeriod} />
            </div>
          </div>
          {filterValue?.company && (
            <div className="px-4 flex space-x-1 items-center text-sm mt-0 text-gray-600" >
              <p>Mostrando resultados para &nbsp;
                <b>{filterValue?.company.name} ({filterValue?.company.symbols.join(', ')})</b>
              </p>
              <MdClear className="text-red-600 text-lg cursor-pointer hover:opacity-75" title="Remover" onClick={() => selectCompany(null)} />
            </div>
          )}
        </div>

        <InfinityScrollContainer className="flex-grow overflow-auto h-1 mini-scrollbar flex flex-col px-4" loadMore={loadMore}>
          {!list && <LoadingBox />}
          {list && list.map((item, i) => (
            <div
              key={`item-${item.idDocumento}`}
              className={`cursor-pointer hover:bg-gray-100 flex space-x-3 p-2 items-center rounded-lg ${selectedItem?.caminhoArquivo === item.caminhoArquivo && "bg-gray-200 hover:bg-gray-200"}`}
              onClick={() => selectItems(item)}
            >
              <div className="h-8 w-8">
                <img className="rounded-[4px]" alt="" src={item.icoUrl} />
              </div>
              <div className="ml-0 w-full">
                <div className="text-sm font-semibold leading-tight relative">
                  {item.subTipoDocumento ? item.subTipoDocumento
                    :
                    <>
                      {item.empresas?.slice(0, 2).map(x => `${x.nomeFantasia} (${x.ativo})`).join(', ')}
                      {item.empresas[0].nomeFantasia && item.empresas?.length - 2 > 0 && `+${item.empresas?.length - 2}`}
                      {item.empresas[0].nomeFantasia && item.empresas?.length > 1 &&
                        <span onClick={(e) => {
                          e.stopPropagation()
                          setModalParams({ open: true, item: item.empresas })
                        }} className="link hover:underline font-bold text-2xs ml-1">{item.permissioned && ' Ver Empresas'}</span>}
                    </>
                  }

                </div>
                <p className="flex text-xs w-full space-x-1 opacity-75 font-semibold">
                  {item.contribuidor} &#x2022; {item.tipoDocumento} &#x2022; {dateStringToLocaleDateString(item.data)}
                </p>
              </div>
              {!item.permissioned && <div className="text-primary text-xl w-8 h-7">
                <img src={iconLiberarAcesso} alt="icone de acesso" />
              </div>}
            </div>
          ))}
        </InfinityScrollContainer>
        {modalParams.open && <CompaniesList closeModal={() => setModalParams({ open: null, item: null })} editItem={modalParams.item} />}
      </div>
    </>
  );
}


const CalendarFilter = React.forwardRef(({ show, onFilterSelected, closeFilter }, ref) => {
  const { filterValue, updatePeriod } = useContext(RecommendationContext)
  const [layoutType, setLayoutType] = useState(filterValue?.customPeriod ? 'CUSTOM' : 'PERIOD')
  const [calendarValue, setCalendarValue] = useState(filterValue?.customPeriod)
  const periodOptions = [{ label: 'Últimos 7 dias', value: 'W' }, { label: 'Últimos 30 dias', value: 'M' }, { label: 'Últimos 12 meses', value: 'Y' }]

  if (!show) return null

  const toggleLayout = (e) => {
    e.stopPropagation()
    setLayoutType(layout => layout === 'CUSTOM' ? 'PERIOD' : 'CUSTOM')
  }


  return (
    <div ref={ref} className="absolute -right-2 drop-shadow-md min-w-[250px] z-50 box-border border-2 bg-white p-4 rounded-lg text-primary space-y-3">
      {
        {
          'PERIOD': (
            <>
              <h3 className="text-sm font-bold">Por período:</h3>
              {
                periodOptions.map(({ value, label }) => (
                  <label className="cursor-pointer items-center flex space-x-1" key={`period-opt-${value}`}>
                    <input
                      type="radio"
                      name="period"
                      value={value}
                      checked={filterValue?.period === value}
                      className="accent-secondary w-[20px] h-[20px] border-t-[4px] border-primary align-middle rounded-full content-[''] cursor-pointer"
                      onChange={e => {
                        updatePeriod(layoutType, e.target.value)
                        closeFilter()
                      }} />
                    <span>{label}</span>
                  </label>
                ))
              }
              <div onClick={toggleLayout} className="text-sm font-bold cursor-pointer hover:opacity-75">Período Personalizado &gt;</div>
            </>
          ),
          'CUSTOM': (
            <>
              <div onClick={toggleLayout} className="cursor-pointer font-bold hover:opacity-75">&lt;&nbsp;&nbsp;Período Personalizado</div>
              <CalendarTitle value={calendarValue} className="mb-4 mt-6" />
              <Calendar onChange={(value) => setCalendarValue(value)} value={calendarValue} selectRange={true} maxDate={new Date()} className="text-sm font-bold" showNavigation={true} />
              <div className="flex justify-center space-x-4 pt-4">
                <button className="btn btn-primary uppercase" onClick={() => {
                  closeFilter()
                  updatePeriod(layoutType, calendarValue)
                }}>Aplicar</button>
              </div>
            </>
          )
        }[layoutType]
      }
    </div>
  )
})

function CalendarTitle({ value, className }) {
  if (!Array.isArray(value))
    return <p className={`${className} text-center font-semibold`}>Selecione uma data:</p>

  return (
    <p className={`${className} text-center font-semibold space-x-4`}>
      <span>
        De&nbsp;<span className="text-black">{dateToLocaleDateString(value[0])}</span>
      </span>
      <span>
        Até&nbsp;<span className="text-black">{dateToLocaleDateString(value[1])}</span>
      </span>
    </p>
  )
}

function SelectedRecomendationModal({ onClose, isIOS }) {

  const { selectedItem } = useContext(RecommendationContext)

  const customStyles = {
    content: {
      top: '0px',
      left: '0px',
      right: '0px',
      bottom: '0px',
      padding: '0px',
      inset: '10px'
    }
  }

  return (
    !isIOS
    && <Modal
      isOpen={!!selectedItem}
      onRequestClose={onClose}
      fullscreen={true}
      style={customStyles}
    >
      <div className="relative h-full">
        <div onClick={onClose} className="h-10 w-10 grid absolute right-9 top-4 justify-items-center align-middle bg-white rounded-full border cursor-pointer hover:text-gray-600 hover:bg-[#EEECF5] text-center">
          <div className="m-auto">
            <MdClose />
          </div>
        </div>
        {selectedItem?.caminhoArquivo ? (
          <iframe
            src={selectedItem.caminhoArquivo + '#view=FitH&toolbar=0&embedded=true'}
            className="w-full h-full p-1 mini-scrollbar"
            title={selectedItem.tipoDocumento}
            referrerPolicy="unsafe-url"
            allow="fullscreen"
          ></iframe>
        ) : (
          <div className="text-grey-400 text-sm p-2">Unavailable</div>
        )}
      </div>
    </Modal>
  )
}

function SelectedRecomendation({ onShowPopup }) {

  const { selectedItem } = useContext(RecommendationContext)

  if (!selectedItem) {
    return (
      <div className="flex justify-center items-center text-gray-500">
        Nenhuma recomendação selecionada
      </div>
    )
  }

  return (
    <div className={`relative h-full p-3 space-y-2 ${!selectedItem.permissioned && 'blur pointer-events-none'}`}>
      <div className="flex justify-between text-sm font-semibold">
        <span className="flex space-x-1 opacity-75">
          {selectedItem.tipoDocumento} &#x2022; {dateStringToLocaleDateString(selectedItem.data)}
        </span>
        <button className="link " onClick={() => onShowPopup(true)}>Ver em tela cheia</button>
      </div>

      <div className="text-xs font-semibold opacity-75">Analistas: {selectedItem.analistas.join(', ')}</div>

      <iframe
        src={selectedItem?.caminhoArquivo + "#view=FitH&toolbar=0"}
        className="w-full h-[90%]"
        title={selectedItem.tipoDocumento}
        referrerPolicy="unsafe-url"
      />
    </div>
  )
}
