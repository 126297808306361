function Authorizing() {

  return (
    <div className="fixed m-auto left-0 right-0 top-0 bottom-0 text-center w-[104px] h-[120px]">
      <iframe title="initialize" src="initialize.html" width="120" height="120" scrolling="no" allowFullScreen />
    </div>
  )
}

export default Authorizing
