import { createRef } from "react";
import { useState } from "react";
import useClickout from "hooks/useClickout";
import { useCustomId } from "hooks/useCustomId";
import { forwardRef } from "react"
import { useContext } from "react"
import { useReducer } from "react"
import { toast } from "react-toastify"
import { MdOutlineDiamond, MdOutlineFilterList } from "react-icons/md"
import { SimpleTooltip } from "shared/HelpTooltip"
import { NewsContext } from "context/NewsContext"

function OriginDropdownTrigger() {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = createRef();
  const dropdownTriggerRef = createRef();

  useClickout([dropdownTriggerRef, dropdownRef], (clickout) => {
    if (clickout) setDropdownOpen(false);
  });
  const buttonId = useCustomId("OriginTriggerButton");

  return (
    <div className="relative z-10">
      <button
        className="text-lg text-primary w-8 h-8 flex items-center justify-center rounded-full hover:bg-light-gray"
        id={buttonId}
        onClick={() => setDropdownOpen(true)}
        ref={dropdownTriggerRef}
      >
        <MdOutlineFilterList />
      </button>

      {isDropdownOpen && (
        <OriginDropdown
          ref={dropdownRef}
          close={() => setDropdownOpen(false)}
          place="right"
        />
      )}
      <SimpleTooltip
        place="top-right"
        anchor={"#" + buttonId}
        value="Escolha a origem da notícia"
      />
    </div>
  );
}

const OriginDropdown = forwardRef(({ close }, ref) => {
  const { filterValue, updateFilters, origins } = useContext(NewsContext);
  const [filtersState, dispatchUpdate] = useReducer((state, action) => {
    const newState = { ...state, ...action.payload };
    if (JSON.stringify(filterValue.origin) === JSON.stringify(newState))
      return newState;
    if (Object.values(newState).every((x) => !x)) {
      toast.warning("É obrigatório passar ao menos uma origem!");
      return state;
    }
    return newState;
  }, filterValue.origin);

  return (
    <div
      ref={ref}
      className="fixed bg-white shadow mt-2 left-2 rounded-sm text-danger border w-30"
      style={{ zIndex: 10000 }}
    >
      {origins.map((orig) => (
        <div className="p-2" key={"news_filter_" + orig.label}>
          <label className="flex items-center space-x-2 cursor-pointer">
            <input
              disabled={!orig.isActive}
              type="checkbox"
              className="accent-primary cursor-pointer"
              onChange={() =>
                dispatchUpdate({
                  payload: { [orig.key]: !filtersState[orig.key] },
                })
              }
              checked={filtersState[orig.key]}
            />
            <span
              className={`font-semibold ${
                orig.isActive ? "text-gray-600" : "text-gray-300"
              } text-sm`}
            >
              {orig.label}
            </span>
            {!orig.isActive && (
              <MdOutlineDiamond className="text-terciary inline" />
            )}
          </label>
        </div>
      ))}
      <div className="p-2">
        <button
          className="btn btn-primary py-2 px-4 text-xs"
          onClick={() => updateFilters({ origin: filtersState }) && close()}
        >
          Aplicar
        </button>
      </div>
    </div>
  );
});

export default OriginDropdownTrigger;
