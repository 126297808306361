import { createContext } from 'react';
import { useSearchParams } from 'react-router-dom'
import { useContext } from 'react'
import useTitle from 'hooks/useTitle'

const SearchContext = createContext({
  searchText: '',
  companiesList: [],
})

const SearchProvider = ({ children }) => {
  const [searchParams] = useSearchParams()
  const searchCompanyName = searchParams.get('empresa')
  const isCompany = !!searchCompanyName
  let searchText = isCompany ? searchCompanyName : searchParams.get('texto')
  useTitle(searchText ? `Busca por "${searchText}"` : 'Busca')

  const contextValue = {
    searchText,
    isCompany
  }

  return (
    <SearchContext.Provider value={contextValue}>
      {children}
    </SearchContext.Provider>
  );
}

const useSearch = () => {
  const context = useContext(SearchContext)
  if (context === undefined) {
    throw new Error('useSearch must be used within a SearchProvider')
  }
  return context
}

export { SearchProvider, useSearch, SearchContext }
