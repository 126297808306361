// import Lib
import { useContext, useEffect, useMemo, useState } from "react"
import { MdFullscreen, MdClose, MdOutlineFilterAlt } from "react-icons/md"

// import Icons
import { HiAdjustmentsHorizontal } from "react-icons/hi2"

// import Shared
import WidgetContextualMenu from "shared/WidgetContextualMenu"
import Tabs from "shared/Tabs"
import { LoadingBox } from "shared/Loading"
import InfinityScrollContainer from "shared/InfinityScrollContainer"
import DeniedPermissionWidget from "shared/DeniedPermissionWidget"
import { SimpleTooltip } from "shared/HelpTooltip"
import { WidgetTitle, WidgetLinkTitle } from "shared/WidgetTitle"
import { dateStringToLocaleString } from "helpers/dateHelper"
import { NewsContext, NewsContextProvider } from "context/NewsContext"
import { useLayoutEngineContext } from "context/LayoutEngineContext"
import NewsApi from "api/NewsApi"

// import Hooks
import useWindowSize from "hooks/useWindowSize"
import { useLocalParams } from "hooks/useLocalParams"
import useTitle from "hooks/useTitle"
import { useCustomId } from "hooks/useCustomId"

// import settings
import { ICONS_MAP, LAYOUT_BREAKPOINT_WIDTH, LAYOUT_BREAKPOINT_WIDTH_MD, NEWS_TABS_KEYS, ORIGINS } from "const"

// import subcomponents
import { ModalFilterNews } from "./modals/ModalFilterNews"
import RelatedNews from "./components/RelatedNews"
import RelatedSymbols from "./components/RelatedSymbols"
import OriginDropdownTrigger from "./components/OriginDropdown"
import ModalShowNews from "./modals/ModalShowNews"


export function NewsWidget(props) {
  const id = props.id
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { currentBreakpoint } = useLayoutEngineContext()
  const widgetSize = props[currentBreakpoint].w || 1

  const compactView = widgetSize === 1

  const options = useMemo(() => [
    { label: "Modo Compacto", onClick: () => props.resizeItem({ id, desktop: { w: 1 } }) },
    { label: "Modo Expandido", onClick: () => props.resizeItem({ id, desktop: { w: 2 } }) },
  ], [id, props])

  const [videosUrl, setVideosUrl] = useState(null)
  const [podcastsUrl, setPodcastsUrl] = useState(null)

  useEffect(() => {
    NewsApi.getUrl('video').then(({ link }) => setVideosUrl(link))
    NewsApi.getUrl('podcast').then(({ link }) => setPodcastsUrl(link))
  }, [])

  return (
    <NewsContextProvider onSelectNews={() => compactView && setIsModalOpen(true)} widgetProps={props}>
      <div className="bg-white h-full rounded flex flex-col">
        <div className="drag-handle pt-4 px-4">
          <WidgetContextualMenu options={options} removeItem={() => props.removeItem(id)} />
          <WidgetLinkTitle title="Notícias" route="/noticias" />
        </div>
        <DeniedPermissionWidget widgetSize={widgetSize} widgetType="News">
          {compactView ? (
            <>
              <NewsList />
              <ModalShowNews isModalOpen={isModalOpen} closeModal={() => setIsModalOpen(false)} />
            </>
          ) : (
            <div className={`flex-grow overflow-hidden relative grid gap-4 ${widgetSize === 2 ? 'grid-cols-2' : 'grid-cols-3'}`}>
              <NewsList gridClass="col-[2/-1]" />
              <SelectedNews />
              {
                widgetSize === 3 && (
                  <div className="overflow-auto mini-scrollbar">
                    <RelatedNews />
                    {videosUrl && <iframe title="Videos" src={videosUrl} className="w-full" style={{ height: 1900 }} />}
                    {podcastsUrl && <iframe title="Podcasts" src={podcastsUrl} className="max-w-full w-full" style={{ height: 900 }} />}
                    <RelatedSymbols />
                  </div>
                )
              }
            </div>
          )}
        </DeniedPermissionWidget>
      </div>
      <ModalFilterNews />
    </NewsContextProvider>
  )
}

export function NewsPage() {

  useTitle('Notícias')

  const [isModalOpen, setIsModalOpen] = useState(false)
  const { width } = useWindowSize()
  const widgetSize = width >= LAYOUT_BREAKPOINT_WIDTH_MD ? (width >= LAYOUT_BREAKPOINT_WIDTH ? 3 : 2) : 1
  const compactView = widgetSize === 1

  const [videosUrl, setVideosUrl] = useState(null)
  const [podcastsUrl, setPodcastsUrl] = useState(null)

  useEffect(() => {
    NewsApi.getUrl('video').then(({ link }) => setVideosUrl(link))
    NewsApi.getUrl('podcast').then(({ link }) => setPodcastsUrl(link))
  }, [])

  const newsParams = useMemo(() => ({
    tabIndex: 0,
    filters: {
      origin: ORIGINS.reduce((ac, x) => ({ ...ac, [x.key]: true }), {}),
    },
  }), [])
  const props = useLocalParams('NEWS_PAGE', newsParams)

  return (
    <NewsContextProvider onSelectNews={() => compactView && setIsModalOpen(true)} widgetProps={props}>
      <div className="bg-white h-full rounded flex flex-col">
        <div className="pt-4 px-4">
          <WidgetTitle title="Notícias" />
        </div>
        {compactView ? (
          <>
            <NewsList />
            <ModalShowNews isModalOpen={isModalOpen} closeModal={() => setIsModalOpen(false)} />
          </>
        ) : (
          <div className={`flex-grow overflow-hidden relative grid gap-4 ${widgetSize === 2 ? 'grid-cols-2' : 'grid-cols-3'}`}>
            <NewsList gridClass="col-[2/-1]" />
            <SelectedNews />
            {
              widgetSize === 3 && (
                <div className="overflow-auto mini-scrollbar">
                  <RelatedNews />
                  {videosUrl && <iframe title="Videos" src={videosUrl} className="w-full" style={{ height: 1900 }} />}
                  {podcastsUrl && <iframe title="Podcasts" src={podcastsUrl} className="max-w-full w-full" style={{ height: 900 }} />}
                  <RelatedSymbols />
                </div>
              )
            }
          </div>
        )}
      </div>
      <ModalFilterNews />
    </NewsContextProvider>
  )
}

function NewsList() {
  const { newsList, selectedNews, tabs, tabIndex, updateTabIndex, selectNews, loadMore, isFiltersValueClean } = useContext(NewsContext)
  const [search, setSearch] = useState('')
  const filteredNews = (newsList || []).filter(news => search ? news.title.toLowerCase().includes(search.toLowerCase()) : true)
  const [showAlert, setShowAlert] = useState(false)

  useEffect(() => {
    const newsAlertDismissed = localStorage.getItem('news_alert_dismissed')
    const isMyNewsTabActive = tabs[tabIndex].key === NEWS_TABS_KEYS.MyNews
    if (isFiltersValueClean && !newsAlertDismissed && isMyNewsTabActive) {
      setShowAlert(true)
    } else {
      setShowAlert(false)
    }
  }, [tabs, tabIndex, showAlert, setShowAlert, isFiltersValueClean])

  const dismissAlert = () => {
    localStorage.setItem("news_alert_dismissed", "true")
    setShowAlert(false)
  }

  return (
    <>
      <div className="space-y-3 flex-col h-full flex pb-4">
        <Tabs defaultTab={tabs.findIndex(item => item.isActive)} tabs={tabs} onChange={({ tab, index }) => updateTabIndex(index)} activeTabIndex={tabIndex} parentClass={`space-x-4 px-3`} />
        <div className="flex items-center space-x-3 px-3">
          <OriginDropdownTrigger />
          <div className="relative flex items-center flex-grow">
            <input value={search} onChange={e => setSearch(e.target.value)} placeholder="Filtrar no título" className="rounded-lg bg-gray-200 p-2 text-sm w-full text-gray-600" type="search" />
            {!search && <MdOutlineFilterAlt className="absolute right-2 text-gray-500" />}
          </div>
          {
            tabIndex === 1 && <CustomFiltersTriggerButton />
          }
        </div>

        {showAlert && (
          <div className="border bg-gray-200 rounded px-2 py-4 flex space-x-2 relative mx-4 mb-4">
            <button title="Fechar alerta" className="absolute right-2 top-2 opacity-50 hover:opacity-100 z-50 text-primary" onClick={() => dismissAlert()}>
              <MdClose />
            </button>
            <div className="flex space-x-2">
              <div className="text-xl">
                <HiAdjustmentsHorizontal className="text-primary" />
              </div>
              <p className="text-sm pr-4">Você pode personalizar seu conteúdo a qualquer momento clicando no ícone disponível acima.</p>
            </div>
          </div>
        )}

        <InfinityScrollContainer className="flex-grow overflow-auto h-1 mini-scrollbar" loadMore={loadMore}>
          {!newsList && <LoadingBox />}
          {newsList && !filteredNews?.length && <p className="p-4 text-gray-500 text-sm">Nenhuma notícia encontrada, tente revisar os filtros.</p>}
          {newsList && filteredNews.length > 0 && filteredNews.map((news, index) => (
            <div key={`news-${index}`} className={`cursor-pointer hover:bg-[#F6F6F6] flex space-x-3 p-2 items-center ${selectedNews?.link === news.link && "bg-[#EDEDED]"}`} onClick={() => selectNews(news)}>
              <img src={ICONS_MAP.get(news.origin)} className={`rounded-full w-5 h-5`} alt={news.origin} />
              <div className="space-y-1">
                <div className={`text-sm font-semibold leading-tight ${selectedNews?.link !== news.link && news.wasRead && 'text-[#AAAAAA]'}`}>
                  {news.title}
                </div>
                <p className="text-xs text-gray-500 font-semibold">
                  {dateStringToLocaleString(news.publicationDate)}
                </p>
              </div>
            </div>
          ))}
        </InfinityScrollContainer>
      </div>
    </>
  )
}

const CustomFiltersTriggerButton = () => {
  const { setIsFilterModalOpen } = useContext(NewsContext)
  const buttonId = useCustomId('CustomFiltersTriggerButton')

  return (
    <div className="relative">
      <button className="text-lg text-primary w-8 h-8 flex items-center justify-center rounded-full hover:bg-light-gray" id={buttonId} onClick={() => setIsFilterModalOpen(true)}>
        <HiAdjustmentsHorizontal />
      </button>
      <SimpleTooltip place="top-left" anchor={'#' + buttonId} value="Personalize o seu conteúdo" />
    </div>
  )
}

function SelectedNews({ gridClass }) {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { selectedNews } = useContext(NewsContext)

  if (!selectedNews) {
    return (
      <div className={`flex justify-center items-center text-gray-500 ${gridClass}`}>
        Nenhuma notícia selecionada
      </div>
    )
  }

  return (
    <>
      <div className={`relative h-full w-full m-auto ${gridClass}`}>
        <RelatedSymbols />
        <button className="absolute right-5 text-lg bg-white" onClick={() => setIsModalOpen(true)} title="Fullscreen" >
          <MdFullscreen />
        </button>
        <iframe src={selectedNews?.link} className={`w-full margin-auto ${selectedNews?.symbols ? 'h-[calc(97%-124px)]' : 'h-[97%]'}`} title={selectedNews.title} />
      </div>
      <ModalShowNews isModalOpen={isModalOpen} closeModal={() => setIsModalOpen(false)} />
    </>
  )
}
