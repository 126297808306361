import { Keys, LAYOUT_BREAKPOINT_WIDTH_MD } from "const"
import { format, convertToInternationalCurrencySystem } from "helpers/numberHelper"
import { memo, useCallback, useContext, useMemo } from "react"
import { SimpleTooltip } from "shared/HelpTooltip"
import { BtnToggleMyList } from "shared/MyList"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { MdDelete, MdDragIndicator } from "react-icons/md"
import { toast } from "react-toastify"
import { HelpTooltip } from "shared/HelpTooltip"
import { useDraggableListItem, useDroppableList } from "hooks/useDnDList"
import { useQuotationPanel } from "../context"
import { useCustomId } from "hooks/useCustomId"
import { LoadingBox } from "shared/Loading"
import { OrderByColumnContext, OrderByColumnContextProvider, OrderByColumnDisplay, OrderByColumnTrigger } from "context/OrderByColumnContext"
import { AssetImg } from "shared/AssetImg"
import { cloneDeep, get, pick } from "lodash"
import AuctionIndicator from "shared/AuctionIndicator"
import Delay from "shared/Delay"
import useMaxWindowSize from "hooks/useMaxWindowSize"
import Blink from "shared/Blink"
import useSymbol from "hooks/useSymbol"

import { ExternalAPI } from "../types"
import useTableColumns from "../hooks/useTableColumns"
import useExternalData from "../hooks/useExternalData"
import useFavoriteList from "../hooks/useFavoriteList"

function QuotationPanelTableView() {

  const { state } = useQuotationPanel()
  const isMobile = useMaxWindowSize(LAYOUT_BREAKPOINT_WIDTH_MD)
  const tableColumns = useTableColumns()
  const dynamicSymbols = useMemo(() => cloneDeep(state.activeList?.symbols || []), [state])

  useFavoriteList()
  useExternalData(tableColumns[state.tableTab]?.dependencies)
  const columns = tableColumns[state.tableTab]?.columns || []

  if (!state.activeList?.type) return null

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="max-w-full overflow-y-auto mini-scrollbar">
        <div className="w-full overflow-auto mini-scrollbar-x">
          <OrderByColumnContextProvider data={dynamicSymbols}>
            {state.loading ? <LoadingBox /> : (
              <table className="relative border-x w-full">
                <thead>
                  <tr>
                    {!(state.activeList.type === "DEFAULT_LIST" || isMobile) && <th className="border-y" />}
                    {columns.map((col) => <TableColumn col={col} key={`COL_${state.tableTab}_${col.id}`} />)}
                    <th className="border-y" />
                  </tr>
                </thead>
                <SymbolTableBody isMobile={isMobile} type={state.activeList.type} columns={columns} dynamicSymbols={dynamicSymbols} />
              </table>
            )}
          </OrderByColumnContextProvider>
        </div>
      </div>
    </DndProvider>
  )
}

function TableColumn({ col }) {
  const notTooltip = ["Ativo", "Empresa"]
  const isTypeName = col.type === "name"

  return (
    <OrderByColumnTrigger key={`COL_${col.header}_${col.accessor}`} columnName={col.accessor}>
      <th className="py-4 border-y cursor-pointer">
        <div className={`flex items-center ${isTypeName ? "justify-start" : "justify-end"}`}>
          <div className={`text-xs font-semibold px-2 ${isTypeName ? "text-left" : "text-right"}`}>
            {col.header.toUpperCase()}
          </div>
          <div className="mb-1">
            {!notTooltip.includes(col.header) && (
              <HelpTooltip
                id={col.id}
                className="top-[10px] w-[232px] p-[5px] text-sm leading-4 rounded font-bold"
                place={"top"}
              >
                <span>{col.description}</span>
              </HelpTooltip>
            )}
          </div>
          <OrderByColumnDisplay columnName={col.accessor} tooltip="Ordenar temporariamente" />
        </div>
      </th>
    </OrderByColumnTrigger>
  )
}

function SymbolTableBody({ type, columns, isMobile, dynamicSymbols }) {
  const { sortedData } = useContext(OrderByColumnContext)

  if (type !== "DEFAULT_LIST" && !isMobile) {
    return <DraggableTableRowContainer originalData={sortedData} columns={columns} dynamicSymbols={dynamicSymbols} />
  }
  return (
    <tbody>
      {sortedData?.map((row) => (
        <tr key={row?.id} className="border-b hover:bg-gray-100" >
          <TableRowData {...{ row, columns }} />
        </tr >
      ))}
    </tbody>
  )
}

function TableRowData({ row, columns }) {
  const { state } = useQuotationPanel()
  const data = useSymbol(row.symbol, row.origin)
  Object.assign(row, {
    ...data,
    ...state.externalData[row.id]
  })

  const renderElement = useCallback(({ type, value }) => {
    if (type === "name")
      return <SymbolNameCell value={value} row={row} />
    if (type === "companyName")
      return <CompanyNameCell value={value} row={row} />
    return <ValueCell value={value} row={row} type={type} />
  }, [row])

  return (
    <>
      {columns.map(cell => (
        <td key={`${row.id}-${cell.id}`}>
          {renderElement({
            type: cell.type,
            value: get(row, cell.accessor) ?? get(row, cell.fallback),
          })}
        </td>
      )
      )}
    </>
  )
}

function DraggableTableRowContainer({ originalData, columns, dynamicSymbols }) {
  const { state, actions } = useQuotationPanel()
  const { data, drop, ...dnd } = useDroppableList({
    type: "TABLE_ROW",
    originalData,
    onSave: (symbols) => {
      dynamicSymbols.splice(0, dynamicSymbols.length, ...symbols)
      actions.updateListOrder(state.activeList, symbols.map(s => pick(s, "symbol", "origin")))
    },
  }, [state, actions])

  const remove = useCallback(async (row) => {
    try {
      await actions.removeSymbol(state.activeList, row)
      toast.success(`${row.symbol} removido do painel ${state.activeList.name} com sucesso!`)
    } catch (error) {
      console.error(error)
      toast.error(`Não foi possível remover ${row.symbol} do painel ${state.activeList.name}...`)
    }
  }, [state, actions])

  return (
    <tbody ref={drop}>
      {data?.filter(row => row).map((row) => (
        <DraggableTableRow key={row.id} dnd={dnd} {...{ row, columns, remove, dnd }} />
      ))}
    </tbody>
  )
}

const DraggableTableRow = memo(function DraggableTableRow({ row, columns, dnd, remove }) {
  const { state } = useQuotationPanel()
  const { isDragging, drag, dropPreviewRef } = useDraggableListItem(row?.id, dnd)

  const opacity = isDragging ? 0.25 : 1

  return (
    <tr ref={dropPreviewRef} className="border-b hover:bg-gray-100" style={{ opacity }}>
      <td ref={drag} className="p-2 text-gray-500 hover:text-primary text-lg cursor-move">
        <MdDragIndicator />
      </td>
      <TableRowData {...{ row, columns }} />
      {
        state.activeList.type !== "INTEREST_LIST"
          ? <td className="p-2 text-gray-500 hover:text-loss cursor-pointer" onClick={() => remove(row)}><MdDelete /></td>
          : <td></td>
      }
    </tr>
  )
})

function SymbolNameCell({ value, row }) {

  const { state } = useQuotationPanel()
  const id = useCustomId("SymbolName")
  const limitedMaxWidth = window.screen.width < 1537
  const toShowNameInOrigins = [1, 9, 687, 1058, 3083, 13118]
  if (toShowNameInOrigins.includes(row.origin)) {
    value = get(state.externalData, [row.id, ExternalAPI.COMPANY, 'name'])?.toUpperCase()
  }

  return (
    <div className="p-2 flex space-x-2">
      <AssetImg symbol={row.symbol} origin={row.origin} size="lg" />
      <div className="flex  flex-1 items-center justify-between">
        <h5 id={`${id}${value}Name`} className="flex font-semibold text-sm mr-1">
          <span className="max-xl:w-24 w-40 line-clamp-2">
            { value || row[Keys.NSINAL] || "-" }
          </span>
        </h5>
        { (limitedMaxWidth && value?.replace(/\s/g, "")?.length > 11) && <SimpleTooltip anchor={`#${id}${value}Name`} value={value} />}
        <div className="flex items-center space-x-1">
          <Delay item={row} />
          <AuctionIndicator className="w-3 p-[5px] ml-0" item={row} />
          <div className="ml-1"><BtnToggleMyList symbol={row.symbol} origin={row.origin} /></div>
        </div>
      </div>
    </div>
  )
}

function CompanyNameCell({ value, row }) {

  const id = useCustomId("CompanyName")

  return (
    <div className="p-2">
      <div className={`flex items-center justify-between`}>
        <h5 id={`${id}${row.symbol}NameBasic`} className="flex font-semibold text-sm whitespace-nowrap mr-1">
          <span className={`max-2xl:w-32 w-44 max-2xl:overflow-hidden max-2xl:text-ellipsis uppercase`}>
            {value || "-"}
          </span>
        </h5>
        {
          (value?.replace(/\s/g, "").length > 15) && <SimpleTooltip anchor={`#${id}${row.symbol}NameBasic`} value={value} />
        }
      </div>
    </div>
  )
}

function ValueCell({ value, row, type }) {
  let className = "bg-opacity-5 rounded py-1"
  let valueToShow = ""

  if (type === "var" || type === "varPercent") {
    className += " " + (
      value === 0
        ? "text-gray-500 bg-gray-500"
        : (value > 0 ? "text-profit bg-profit" : "text-loss bg-loss")
    )
  }
  if (type === "varPercent") {
    if (value > 0) {
      valueToShow += "+"
    }
    valueToShow += format(value)
    valueToShow += "%"
  }
  if (type === "integer") {
    valueToShow = format(value, "integer")
  }
  if (type === "bigNumber") {
    valueToShow = convertToInternationalCurrencySystem(value)
  }
  if ((type === "var") && value > 0) {
    valueToShow += "+"
  }
  if (type === "float") {
    valueToShow += format(value, "float")
  }
  if (type === "cdec" || type === "var") {
    const cdec = row[ExternalAPI.COMPANY]?.cdec
    if (typeof cdec === "number") {
      valueToShow += Number(value).toLocaleString("pt-BR", {
        minimumFractionDigits: Math.min(cdec, 4),
        maximumFractionDigits: Math.min(cdec, 4)
      })
    } else {
      valueToShow += format(value, "float")
    }
  }
  return (
    <div className="py-2 px-2 font-semibold text-right text-sm">
      {
        typeof value !== "number" ? "-" : (
          <Blink className={className} value={value}>
            {valueToShow}
          </Blink>
        )
      }
    </div>
  )
}

export default QuotationPanelTableView
