import useWidgets from 'hooks/useWidgets'
import { Link } from "react-router-dom"
import { FormProvider, useForm } from 'react-hook-form'
import { MdOutlineDiamond } from 'react-icons/md'
import Modal from 'react-modal'
import { Checkbox } from 'shared/Checkbox'
import CloseButton from 'shared/CloseButton'
import { generateWidgetId } from 'helpers/generateWidgetId'

function ModalAddModules({ modalIsOpen, closeModal, submitData }) {

  const { getWidgetsByCategory, widgets } = useWidgets()
  const widgetsByCategory = getWidgetsByCategory()
  const methods = useForm()

  const onSubmit = (data) => {

    const widgetTypes = Object
      .keys(data)
      .filter(checkboxName => data[checkboxName])
      .map(checkboxName => {
        let [type, genericId] = checkboxName.split(':')
        genericId = genericId ? +genericId : null
        const widgetFrame = widgets.find(x => x.type === type && (genericId ? genericId === x.genericId : true))
        return ({ 
          id: generateWidgetId(type, genericId),
          defaults: widgetFrame.defaults || {} 
        })
      })
    methods.reset()

    submitData(widgetTypes)
  }

  return (
    <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={{ content: { maxWidth: '512px', margin: 'auto', borderRadius: '12px' } }}>
      <CloseButton onClick={closeModal} />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className="h-full flex flex-col items-center">
          <div className="flex-grow">
            <h2 className="text-primary uppercase text-xl font-bold mb-2">Adicione Novo(s) Módulo(s)</h2>
            <h4 className="text-primary uppercase text-base font-semibold mb-2">Escolha o(s) módulo(s) que deseja adicionar:</h4>
            {
              widgetsByCategory.map(({ category, items }) => (
                <div key={'category-' + category} className="mb-2">
                  <h6 className="text-primary text-base font-semibold my-1">{category}</h6>
                  <div className="grid sm:grid-cols-2 grid-cols-1 gap-1">
                    {
                      items.map(({ type, genericId, label, restricted }) => {
                        const key = genericId ? `${type}:${genericId}` : type
                        return (
                          <Checkbox className="text-primary" key={key} disabled={restricted} name={key}>
                            <span className="ml-2 text-base flex items-center">
                              {label}
                              {restricted && <MdOutlineDiamond className="text-base text-terciary ml-2 inline" />}
                            </span>
                          </Checkbox>
                        )
                      })
                    }
                  </div>
                </div>
              ))
            }

            <div className="text-terciary my-5">
              <p className="text-sm font-bold uppercase flex items-center space-x-2 mt-5">
                <MdOutlineDiamond className='flex-none text-base' />
                <span>Destrave conteúdo e funcionalidades</span>
              </p>
              <p className="text-sm font-semibold">
                <span>Conheça nossos planos e os recursos indispensáveis para a melhor decisão de negócios. </span>
                <Link to={'/planos'} className='underline'>Faça upgrade.</Link>
              </p>
            </div>
          </div>
          <button type="submit" className="btn btn-primary text-secondary inline">Adicionar</button>
        </form>
      </FormProvider>

    </Modal>
  )
}

export default ModalAddModules
