import { usePermission } from "hooks/usePermission"
import { useQuotationPanel } from "../context"

function useListGroups() {

  const { state } = useQuotationPanel()
  const walletPermission = usePermission('Wallet')
  
  const walletList = { ...state.lists.find(x => x.type === 'WALLET_LIST'), permissioned: !!walletPermission }
  const customLists = [...state.lists.filter(x => x.type === 'QUOTATION_LIST'), state.lists.find(x => x.type === 'INTEREST_LIST'), walletList]
  const defaultLists = state.lists.filter(x => x.type === 'DEFAULT_LIST').map(item => ({ ...item, order: item.order || 0 })).sort((x, y) => x.order > y.order ? 1 : -1)

  return {
    walletList,
    customLists, 
    defaultLists
  }

}

export default useListGroups