import { memo, useCallback, useEffect, useMemo, useState } from "react"
import { capitalize, cloneDeep, get } from "lodash"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { toast } from "react-toastify"
import { MdDragIndicator } from "react-icons/md"

import { LAYOUT_BREAKPOINT_WIDTH_MD, Keys } from "const"

import Delay from "shared/Delay"
import Blink from "shared/Blink"
import Price from "shared/Price"
import AuctionIndicator from "shared/AuctionIndicator"
import { BtnToggleMyList } from "shared/MyList"
import { SimpleTooltip } from "shared/HelpTooltip"
import { AssetImg } from "shared/AssetImg"
import iconPanelDelete from "assets/icons/panel/botao-excluir.svg"
import { abs, convertToInternationalCurrencySystem, format } from "helpers/numberHelper"

import useSymbol from "hooks/useSymbol"
import useMaxWindowSize from "hooks/useMaxWindowSize"
import { useDraggableListItem, useDroppableList } from "hooks/useDnDList"
import { useCustomId } from "hooks/useCustomId"

import { useQuotationPanel } from "../context"
import { ExternalAPI } from "../types"
import useExternalData from "../hooks/useExternalData"
import useFavoriteList from "../hooks/useFavoriteList"

function QuotationPanelCardView() {
  const { state } = useQuotationPanel()
  const isMobile = useMaxWindowSize(LAYOUT_BREAKPOINT_WIDTH_MD)
  useExternalData([ExternalAPI.COMPANY, ExternalAPI.CONSENSUS])
  useFavoriteList()

  // Return List or Draggable List
  if (state.activeList.type !== "DEFAULT_LIST" && !isMobile) {
    return (
      <DndProvider backend={HTML5Backend}>
        <DraggableListContainer />
      </DndProvider>
    )
  }

  return (
    <div className="flex-grow h-full overflow-auto mini-scrollbar">
      {state.activeList.symbols.map((item, index) => (
        <div key={item.id} className="border border-gray-200 border-b-0 last:border-b last:rounded-bl-md last:rounded-br-md first:rounded-tl-md first:rounded-tr-md hover:bg-gray-100">
          <SymbolListItem index={index} {...item} />
        </div>
      ))}
    </div>
  )
}

function DraggableListContainer() {
  const { state, actions } = useQuotationPanel()
  const { data, drop, ...dnd } = useDroppableList({
    type: "CARD_LIST_ROW",
    originalData: state.activeList.symbols,
    onSave: (symbols) => actions.updateListOrder(state.activeList, symbols),
  }, [state, actions])

  const remove = useCallback(async (row) => {
    try {
      await actions.removeSymbol(state.activeList, row)
      toast.success(`${row.symbol} removido do painel ${state.activeList.name} com sucesso!`)
    } catch (error) {
      console.error(error)
      toast.error(`Não foi possível remover ${row.symbol} do painel ${state.activeList.name}...`)
    }
  }, [state, actions])

  return (
    <div className="flex-grow h-full overflow-auto mini-scrollbar">
      {data.map((row, index) => (
        <DraggableListItem key={row.id} row={row} dnd={dnd} remove={remove} />
      ))}
    </div>
  )
}

const DraggableListItem = memo(function DraggableListItem({ row, remove, dnd }) {
  const { state } = useQuotationPanel()
  const { isDragging, drag, dropPreviewRef } = useDraggableListItem(row?.id, dnd)
  const opacity = isDragging ? 0.25 : 1
  const id = useCustomId()

  const DragHandler = useMemo(() => (
    <div className="text-right flex items-center justify-start w-6 invisible group-hover:visible cursor-grab" ref={drag}>
      <button className="text-primary text-xl show-on-hover cursor-grab w-full">
        <MdDragIndicator />
      </button>
    </div>
  ), [drag])

  const DeleteHandler = useMemo(() => {
    if (state.activeList?.type === "INTEREST_LIST")
      return null
    return (
      <div className="icon-delete flex items-center justify-end w-6 invisible group-hover:visible">
        <button className="text-loss text-xl show-on-hover" id={id} onClick={() => remove(row)}>
          <img src={iconPanelDelete} alt="Excluir" />
        </button>
        <SimpleTooltip anchor={`#${id}`} value="Excluir" className="text-xs" />
      </div>
    )
  }, [id, remove, row, state])

  return (
    <div
      style={{ opacity }}
      ref={dropPreviewRef}
      className="border border-gray-200 border-b-0 last:border-b last:rounded-bl-md last:rounded-br-md first:rounded-tl-md first:rounded-tr-md hover:bg-gray-100">
      <SymbolListItem
        {...row}
        prepend={() => DragHandler}
        append={() => DeleteHandler}
      />
    </div >
  )
})

function SymbolListItem({ symbol, origin, prepend, append, ...item }) {

  const { state, widgetSize } = useQuotationPanel()
  const cdec = get(state.externalData, [item.id, ExternalAPI.COMPANY, 'cdec'], 2)
  const name = get(state.externalData, [item.id, ExternalAPI.COMPANY, 'name'], symbol)
  const symbolData = useSymbol(symbol, origin, null, { cdec })
  const breakpoints = useMemo(() => [480, 768, 1024, 1280, 1365], [])
  const [breakpointsMap, setBreakpointsMap] = useState(null)
  const checkBreakpoint = breakpointIndex => breakpointsMap && breakpointsMap[breakpoints[breakpointIndex]]
  const getRealSize = useCallback(() => {
    return (document.getElementById(state.widgetProps?.id) || document.body)?.getBoundingClientRect()
  }, [state])

  useEffect(() => {
    const handleResize = () => {
      const width = getRealSize()?.width
      setBreakpointsMap(breakpoints.reduce((ac, bp) => ({ ...ac, [bp]: width > bp }), {}))
    }
    handleResize()
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [getRealSize, breakpoints])

  return (
    <div className="p-2 group items-center flex justify-between" key={"SymbolCardListItem-" + symbol}>
      <div className={`flex items-center overflow-hidden whitespace-nowrap text-ellipsis w-72 ${widgetSize < 2 && "flex-grow"}`}>
        {typeof prepend === "function" && prepend()}
        <div className="space-x-2 flex items-center flex-1 w-full">
          <AssetImg symbol={symbol} origin={origin} />
          <SymbolName symbolData={symbolData} name={name} symbolId={item.id} />
        </div>
      </div>
      <div className="w-32">
        <Price symbol={symbol} origin={origin} quoteDate={true} props={{ cdec }} varClass="text-xs flex justify-end" containerClass="flex flex-col items-end" />
      </div>
      {checkBreakpoint(0) && <div className="w-32"><MinMax data={symbolData} /></div>}
      {checkBreakpoint(1) && <div className="w-32"><AskBid data={symbolData} /></div>}
      {checkBreakpoint(2) && <div className="w-44"><Volume data={symbolData} /></div>}
      {checkBreakpoint(3) && <div className="w-40 min-w-[7rem]"><VarYearVarMonth data={symbolData} /></div>}
      {checkBreakpoint(4) && <div className="w-40 max-w-[16rem] min-w-[16rem] flex-grow"><Consensus symbolData={symbolData} symbolId={item.id} /></div>}
      {typeof append === "function" ? append() : <div className="w-1" />}
    </div>
  )

}

function SymbolName({ symbolData, name }) {
  const { symbol, origin } = symbolData || {}
  const id = useCustomId('symbolName_' + symbol)
  let nameSymbol = name || (symbolData && symbolData[Keys.NSINAL]) || symbol

  const toShowNameInOrigins = useMemo(() => [1, 9, 687, 1058, 3083, 13118], [])
  const isOriginShown = toShowNameInOrigins.includes(origin)

  if (isOriginShown) {
    nameSymbol = nameSymbol.split(' ').map(capitalize).join(' ')
  }

  return (
    <div className="flex flex-col w-full">
      <div className={`flex ${isOriginShown ? "items-start" : "items-center"} justify-between font-semibold space-x-1`}>
        <div className={`flex ${isOriginShown ? "items-start mt-1" : "items-center"} max-2xl:w-24 w-40 gap-x-1 flex-grow`}>
          <h5 id={`title_${id}`} className={(isOriginShown && "w-full whitespace-normal") + ` text-sm`}>
            {isOriginShown ? nameSymbol : (symbol || name)}
          </h5>
          <div className="flex flex-row-reverse flex-grow">
            {(isOriginShown && nameSymbol?.replace(/\s/g, "")?.length > 9) && <SimpleTooltip anchor={`#title_${id}`} value={nameSymbol} />}
            <Delay item={symbolData} className="flex-none w-3" />
            <AuctionIndicator item={symbolData} className="w-3 ml-0" />
          </div>
        </div>
        <BtnToggleMyList id={symbol} symbol={symbol} origin={origin} className={`max-sm:ml-4 ${isOriginShown && "mt-1"}`} />
      </div>
      {!isOriginShown && (
        <h6 id={"subtitle_" + id} className="text-xs text-gray-500 overflow-hidden whitespace-normal line-clamp-1" style={{ maxWidth: "90%" }}>
          {nameSymbol}
          {(nameSymbol?.length > 11) && <SimpleTooltip anchor={`#subtitle_${id}`} value={nameSymbol} />}
        </h6>
      )}
    </div>
  )
}

function BlinkValue({ data, dataKey }) {
  
  // const fractionDigits = Math.min((data?.cdec || 0), 4)
  const fractionDigits = Math.min(2)
  if (!data || !dataKey || !data[dataKey]) {
    return "-"
  }
  const format = {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits
  }
  return <Blink value={data[dataKey].toLocaleString("pt-BR", format)} />
}

function AskBid({ data }) {
  return (
    <div className="grid grid-cols-2 text-sm gap-1 items-center col-span-2 text-gray-500">
      <p className="text-right text-xs font-bold">Compra</p>
      <p className="text-black">
        <BlinkValue data={data} dataKey={Keys.OCP} />
      </p>
      <p className="text-right text-xs font-bold">Venda</p>
      <p className="text-black">
        <BlinkValue data={data} dataKey={Keys.OVD} />
      </p>
    </div>
  )
}

function MinMax({ data }) {
  return (
    <div className="grid grid-cols-2 text-sm gap-1 items-center col-span-2 text-gray-500">
      <p className="text-right text-xs font-bold">Máx.</p>
      <p className="text-black">
        <BlinkValue data={data} dataKey={Keys.MAX} />
      </p>
      <p className="text-right text-xs font-bold">Mín.</p>
      <p className="text-black">
        <BlinkValue data={data} dataKey={Keys.MIN} />
      </p>
    </div>
  )
}

function Volume({ data }) {
  return (
    <div className="grid grid-cols-3 text-sm gap-1 items-center min-w-[5rem] text-gray-500">
      <p className="text-right col-span-2 text-xs font-bold">Vol. finan.</p>
      <p className="text-black whitespace-nowrap">
        {data && data[Keys.VOLF] ? <Blink value={convertToInternationalCurrencySystem(data[Keys.VOLF])} /> : "-"}
      </p>
      <p className="text-right col-span-2 text-xs font-bold">Negócios</p>
      <p className="text-black whitespace-nowrap">
        {data && data[Keys.NEG] ? <Blink value={convertToInternationalCurrencySystem(data[Keys.NEG])} /> : "-"}
      </p>
    </div>
  )
}

function VarYearVarMonth({ data }) {
  const varYear = data ? data[Keys.VARANO] : null
  const var12Months = data ? data[Keys.VAR1ANO] : null

  return (
    <div className="grid grid-cols-2 text-sm gap-1 items-center col-span-2 text-gray-500">
      <p className="text-right text-xs font-bold">Var. ano</p>
      {varYear ? (
        <Blink value={varYear} className={"font-semibold " + (varYear === 0 ? "text-gray-500" : varYear > 0 ? "text-profit" : "text-loss")}>
          {abs(varYear, "percent")}
        </Blink>
      ) : "-"}
      <p className="text-right text-xs font-bold">Var. 12m.</p>
      {var12Months ? (
        <Blink value={var12Months} className={"font-semibold " + (var12Months === 0 ? "text-gray-500" : var12Months > 0 ? "text-profit" : "text-loss")}>
          {abs(var12Months, "percent")}
        </Blink>
      ) : "-"}
    </div>
  )
}

function Consensus({ symbolData, symbolId }) {
  const { symbol } = symbolData || {}
  const actualPrice = symbolData && symbolData[Keys.ULTCOT]

  const { state } = useQuotationPanel()
  const consensusData = cloneDeep(get(state.externalData, [symbolId, ExternalAPI.CONSENSUS], null))

  if (!consensusData)
    return null
  if ((!consensusData?.detalhes || !symbol) && !symbolData)
    return null

  if (consensusData.media && actualPrice) {
    consensusData.potencialValorizacao = consensusData.media / actualPrice
    consensusData.potencialValorizacaoPercent = abs((consensusData.potencialValorizacao - 1) * 100, 'percent')
  }

  // draw consensus
  const displayMap = {
    'Compra': { color: 'bg-profit', order: 1 },
    'Neutro': { color: 'bg-gray-300', order: 2 },
    'Venda': { color: 'bg-loss', order: 3 },
    'Em Revisão': { color: 'bg-amber-400', order: 4 },
  }
  const analystsOpinion = (consensusData?.detalhes || [])?.map((item) => {
    if (item.recomendacao === "Neutra")
      item.recomendacao = "Neutro"
    return {
      id: displayMap[item.recomendacao].order,
      color: displayMap[item.recomendacao].color,
      recomendacao: item.recomendacao,
      analistas: item.analistas,
    }
  }).sort((a, b) => a.id < b.id ? - 1 : a.id > b.id ? 1 : 0)

  const getWidth = (n) => (100 / consensusData.recomendacoes) * n + "%"


  const description = analystsOpinion.reduce((ac, { recomendacao, analistas }, index) => {
    if (index > 0)
      ac += '<br >'
    return ac + `${recomendacao}: ${analistas}`
  }, "")

  return (
    <>
      <div className={`flex flex-col justify-between ${symbol}`}
        data-data-tooltip-id={symbol}
        data-tooltip-html={`${description}`}>
        {
          analystsOpinion.length > 0 && (
            <div className="items-center flex justify-between mb-1">

              <div className="text-xs text-gray-500 font-bold">Recomendação</div>
              <div className="rounded-lg overflow-hidden flex w-32">
                {
                  analystsOpinion.map(({ recomendacao, analistas, color }) => (
                    <div
                      key={`${symbol}_metric_${recomendacao}`}
                      className={`${color} text-center font-semibold h-2 cursor-pointer`}
                      style={{ width: getWidth(analistas) }}
                    />
                  ))
                }
              </div>
            </div>
          )
        }

        <div className="flex justify-between">
          <div className="col-span-2 flex space-x-1">
            <div className="self-end justify-self-end text-xs text-gray-500 font-bold">Potencial</div>
            <div className="self-end text-sm text-black">
              {consensusData?.potencialValorizacaoPercent || <div className="font-normal text-sm">-</div>}
            </div>
          </div>
          <div className="col-span-2 flex space-x-1">
            <div className="self-end justify-self-end text-xs text-gray-500 font-bold">Preço-alvo</div>
            <div className="self-end text-sm text-black text-center">
              {consensusData?.media
                ? <span>{format(consensusData.media, 'float')}</span>
                : "-"}
            </div>
          </div>
        </div>
      </div>
      <SimpleTooltip anchor={`.${symbol}`} className="text-xs text-left" />
    </>
  )
}

export default QuotationPanelCardView