import AuthorizationApi from "api/AuthorizationApi"
import ComDinheiroApi from "api/ComDinheiroApi"
import PreferencesApi from "api/PreferencesApi"
import { LAYOUT_BREAKPOINT_WIDTH_MOBILE } from "const"
import AccessInterceptorContext from "context/AccessInterceptorContext"
import AuthContext from "context/AuthContext"
import useMobile from "hooks/useMobile"
import { usePermission } from "hooks/usePermission"
import useTitle from "hooks/useTitle"
import useWindowSize from "hooks/useWindowSize"
import { useCallback, useContext, useEffect, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { MdErrorOutline, MdKeyboardArrowDown, MdOpenInNew, MdOutlineAccountBalanceWallet, MdOutlineDiamond, MdOutlineLink, MdOutlineLinkOff, MdOutlineManageAccounts, MdOutlineWarningAmber } from 'react-icons/md'
import { Link, Outlet, useNavigate, useResolvedPath } from "react-router-dom"
import { toast } from "react-toastify"
import Card from "shared/Card"
import { CheckboxBasic } from 'shared/Checkbox'
import { MaskedInput } from "shared/MaskedInput"
import NotificationModal from 'shared/modal/NotificationModal'

export const navigationItems = [
  { label: 'Minha conta', url: 'minha-conta', Ico: MdOutlineManageAccounts, Element: MyAccount },
  { label: 'Módulo minha carteira', url: 'minha-carteira', Ico: MdOutlineAccountBalanceWallet, Element: MyWallet },
  // { label: 'Portal do Assinante', url: 'portal-do-assinante', Ico: MdOutlineStarOutline, Element: SignaturePortal },
  // { label: 'Ticker de Cotação', url: 'ticker-de-cotacao', Ico: MdOutlinePushPin, Element: Ticket },
]

const keyConfig = 'configPage'

function Settings() {
  useTitle('Configurações')

  const { width } = useWindowSize()
  const [selectedUrl, setSelectedUrl] = useState()
  const [selectedItem, setSelectedItem] = useState(navigationItems[0])
  const [isOpen, setIsOpen] = useState(false)
  const { pathname } = useResolvedPath()
  const navigate = useNavigate()
  const permission = usePermission('Wallet')

  navigationItems.forEach(x => {
    if (x.url === 'minha-carteira') x.permission = permission
  })

  useEffect(() => {
    const url = pathname.split('/')[2] || null
    setSelectedUrl(url)
    if (!url)
      navigate('./' + navigationItems[0].url, { relative: "path" })
  }, [pathname, navigate, setSelectedUrl])


  function SelectOption({ option }) {

    const { setIsAccessBlocked } = useContext(AccessInterceptorContext)

    return (
      <Link onClick={() => {
        if (!permission && option.url === 'minha-carteira') {
          setIsAccessBlocked(true)
          return
        }
        setIsOpen(!isOpen)
        setSelectedItem(option)
      }}
        key={option.url} className={`cursor-pointer grid grid-cols-[40px_1fr] py-4 px-6 text-white bg-primary rounded-lg items-center mb-0.5 ${option.url === 'minha-carteira' && !permission && ''}`}>
        <option.Ico className="text-3xl text-secondary" />
        <span className="text-lg">{option.label}</span>
        {option.url === 'minha-carteira' && !permission && < MdOutlineDiamond className="text-white inline text-lg w-4 min-w-[1rem] absolute right-5" />}
      </Link >
    )
  }

  return (
    <Card className="min-h-full m-4">
      <h1 className="text-[20px] text-primary font-bold uppercase mb-4">Configurações</h1>

      {width < 1280 ?
        <div className="relative">
          <SelectOption option={selectedItem} />
          <MdKeyboardArrowDown className={`z-10 text-secondary text-2xl top-5 absolute right-3 cursor-pointer ${isOpen && "rotate-180"}`} />
          {isOpen && (
            <div className="absolute z-50 w-full">
              {navigationItems.filter((x) => x.label !== selectedItem.label).map((option) => <SelectOption option={option} />)}
            </div>
          )}
          <selectedItem.Element />
        </div>
        :
        <section className="grid lg:grid-cols-[1fr_3fr] gap-4">
          <nav className="space-y-2">
            {
              navigationItems.map(({ label, url, Ico, permission }) => (
                <div
                  onClick={() => {
                    if (!permission && url === 'minha-carteira') {
                      // setIsAccessBlocked(true)
                      return
                    }
                    navigate(`/configuracoes/${url}`)
                  }}
                  key={url} className={`relative grid grid-cols-[40px_1fr] py-4 px-5 text-white bg-primary rounded-lg items-center cursor-pointer ${url === selectedUrl ? 'brightness-75' : ''} ${url === 'minha-carteira' && !permission && ''}`}>
                  <Ico className="text-3xl text-secondary" />
                  <span className="text-lg">{label}</span>
                  {url === 'minha-carteira' && !permission && < MdOutlineDiamond className="text-white inline text-lg w-4 min-w-[1rem] absolute right-5" />}
                </div>
              ))
            }
          </nav>
          <Outlet />
        </section>
      }
    </Card>
  )
}

function MyAccount() {
  const { width } = useWindowSize()
  const { userInfo } = useContext(AuthContext)
  const navigate = useNavigate()
  const [isOpenNotification, setIsOpenNotification] = useState({ status: false })
  const [isOpenConfirmation, setIsOpenConfirmation] = useState({ status: false })
  const [isDegustation, setIsDegustation] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [dateEndSignature, setDateEndSignature] = useState(userInfo.dateEndSignature?.replace(/(\d{4})-(\d{2})-(\d{2})/, "$3/$2/$1"))
  const [cancelDegustationDate, setCancelDegustationDate] = useState(userInfo?.cancelDegustation?.split('T')[0].split('-').reverse().join('-'))
  const isMobile = width < LAYOUT_BREAKPOINT_WIDTH_MOBILE
  const formattedTodayDate = new Date().toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }).replace(/\//g, '-')
  // const cancelDegustationDate = userInfo?.cancelDegustation?.split('T')[0].split('-').reverse().join('-')
  const getSignature = (signature) => {
    switch (signature) {
      case "VONEBAS":
        return "Basic"
      case "VONEINT":
        return "Standard"
      case "VONEAVA":
        return "Advanced"
      default:
        return 'Free'
    }
  }


  async function cancelDegustation() {
    try {
      setIsLoading(true)
      const response = await AuthorizationApi.cancelDegustation()
      if (response.status !== 'OK') {
        toast.error('Falha ao cancelar degustação!')
        setIsOpenNotification({ status: false })
        setIsLoading(false)
        return
      }
      const data = {
        isDegustation: false
      }
      await PreferencesApi.updateConfiguration({ key: keyConfig, data: JSON.stringify(data) })
      setIsOpenConfirmation({ status: true })
      setIsLoading(false)
      setIsOpenNotification({ status: false })
    } catch (error) {
      console.error(error)
      toast.error('Falha ao cancelar degustação!')
    }
  }

  function closeNotification() {
    setIsOpenNotification({ status: false })
  }

  function redirectUser() {
    setIsOpenConfirmation({ status: false })
    setDateEndSignature(formattedTodayDate)
    setCancelDegustationDate(formattedTodayDate)
    setIsDegustation(false)
  }

  async function getConfig(key) {
    return await PreferencesApi.getConfiguration(key)
  }

  useEffect(() => {
    if (userInfo.type === "DEGUSTACAONO") {
      getConfig(keyConfig).then(({ result }) => {
        const data = JSON.parse(result.data)
        if (!data) setIsDegustation(userInfo.cancelDegustation ? false : true)
        setIsDegustation(data.isDegustation)
      })
    }
  }, [])

  return (
    <div>
      <h4 className="text-xl uppercase text-primary font-semibold mb-[10px]">Minha conta</h4>
      <div className="mb-8 grid grid-cols-1 gap-[10px]">
        <div className="font-bold">Usuário: {userInfo.name}</div>
        <div className="font-bold">Plano: {`${getSignature(userInfo.signature)} ${userInfo.type === 'DEGUSTACAO' ? 'Cortesia' : ''}`}</div>
        {(userInfo.type === 'DEGUSTACAONO' && !userInfo.cancelDegustation && isDegustation) &&
          <>
            <div className="border-2 border-gray-300 bg-gray-200 p-[30px] space-x-2 flex rounded-lg max-w-xl items-start">
              <div className="py-1">
                <MdOutlineWarningAmber className="text-warning text-[20px] leading-[28px] text-amber-400" />
              </div>
              <div className="">
                <p><strong>Atenção: </strong> Seu plano {getSignature(userInfo.signature)} Cortesia é válido até <strong>{dateEndSignature} </strong>
                  e será convertido automaticamente em um plano anual {getSignature(userInfo.signature)}, nas <span onClick={() => navigate('/planos')} className="font-bold underline cursor-pointer link" target="_blank" rel="noreferrer" >condições comerciais vigentes para assinantes
                    do Valor</span>, caso não haja cancelamento prévio. </p>
              </div>
            </div>
            <button onClick={() => setIsOpenNotification({ status: true, item: userInfo.signature })} className={`${width < 417 && 'w-auto'} max-w-[230px] rounded-xl font-semibold bg-yellow-500 flex items-center justify-center space-x-2 py-4 uppercase`}>
              <span>Cancelar Cortesia</span>
            </button>
          </>
        }
        {cancelDegustationDate === formattedTodayDate &&
          <>
            <div className="border-2 border-gray-300 bg-gray-200 p-[30px] space-x-2 flex rounded-lg max-w-xl items-start">
              <div className="py-1">
                <MdOutlineWarningAmber className="text-lg text-warning text-amber-400" />
              </div>
              <p className="flex-grow">
                <b>Atenção: </b> Seu plano {getSignature(userInfo.signature)} Cortesia foi cancelado e será substituído pelo plano Grátis a partir das 00h00 de amanhã. Caso mude de ideia e queira voltar a acessar mais recursos
                e ferramentas exclusivas do Valor One, acesse nossa página de <p className="font-bold underline cursor-pointer link" target="_blank" rel="noreferrer" onClick={() => navigate('/planos')}>Planos</p> e assine o melhor plano para o seu perfil.
              </p>
            </div>
          </>
        }
      </div>

      <div className="mb-[30px] grid grid-cols-1 gap-3">
        <h4 className="text-xl font-semibold uppercase text-primary">Conta Globo</h4>
        <p>Nesta seção você gerencia seus dados pessoais, retifica informações e pode pedir a exclusão de dados, de acordo com nossa <a className="font-bold underline link" target="_blank" rel="noreferrer" href="https://privacidade.globo.com/privacy-policy/">política de privacidade</a>.</p>
        <a href="http://minhaconta.globo.com/" target="_blank" rel="noreferrer" className={`${width < 417 && 'w-auto'} max-w-[294px] rounded-xl font-semibold bg-primary text-secondary flex items-center justify-center space-x-2 py-4 uppercase`}>
          <span>Gerenciar meus dados</span>
          <MdOpenInNew className="text-xl" />
        </a>
      </div>

      <div className="grid grid-cols-1 gap-3 mb-8">
        <h4 className="text-xl font-semibold uppercase text-primary">Portal do assinante</h4>
        <p>Nesta seção você gerencia suas assinaturas e pode alterar dados de cartão de crédito para cobrança de serviços.</p>
        <a href="https://editoraglobo.my.site.com/assinante/s/" target="_blank" rel="noreferrer" className={`${width < 417 && 'w-auto'} max-w-[346px] btn px-0 btn-primary text-secondary justify-center flex items-center space-x-2 py-4 uppercase mb-7`}>
          <span>Gerenciar minha assinatura</span>
          <MdOpenInNew className="text-xl" />
        </a>
      </div>

      <NotificationModal
        isOpen={isOpenNotification.status}
        closeModal={() => closeNotification()}
        isMobile={isMobile}
        title={`Tem certeza que deseja cancelar agora e perder as condições especiais para acessar os benefícios do Valor One?`}
        text={<div className="text-left pt-[10px]">Seu plano não tem custos até <strong>{userInfo.dateEndSignature?.replace(/(\d{4})-(\d{2})-(\d{2})/, "$3/$2/$1")}</strong></div>}
        style={{ content: { maxWidth: isMobile ? width : 416, margin: "auto", marginRight: isMobile ? -40 : "auto", marginLeft: isMobile ? -40 : "auto", maxHeight: width < 367 ? '43%' : 298, borderRadius: 10, padding: 20, overflowY: "auto" } }}
        successModal={() => cancelDegustation()}
        successLabel="Cancelar Cortesia"
        closeLabel="voltar"
        actionButtons
        confirmButtonClass={isLoading && 'opacity-40 pointer-events-none'}
        cancelButtonClass={isLoading && 'opacity-40 pointer-events-none'}
        cancel={isLoading && 'opacity-40 pointer-events-none'}
      // children={isLoading && <Loading size="30" />}
      />


      <NotificationModal
        isOpen={isOpenConfirmation.status}
        closeModal={() => redirectUser()}
        icon="success"
        isMobile={isMobile}
        title={`Plano cancelado com sucesso`}
        text={<div className="mt-2 text-center"><span>Nenhuma cobrança será feita.</span> <br /> A qualquer momento você pode acessar nossos <p className="font-bold underline cursor-pointer link" target="_blank" rel="noreferrer" onClick={() => navigate('/planos')}>Planos</p> e fazer uma nova contratação.</div>}
        style={{ content: { maxWidth: isMobile ? width : 408, margin: "auto", marginRight: isMobile ? -40 : "auto", marginLeft: isMobile ? -40 : "auto", maxHeight: 298, borderRadius: 10, padding: 20 } }}
        closeLabel="ok"
        actionButtons="one" />

    </div >
  )
}

// function SignaturePortal() {
//   return (
//     <div className="space-y-4">
//       <h4 className="text-xl font-semibold uppercase text-primary">Portal do Assinante</h4>
//       <p>Nesta seção você gerencia suas assinaturas e pode alterar dados de cartão de crédito para cobrança de serviços.</p>
//       <a href="https://editoraglobo.my.site.com/assinante/s/" target="_blank" rel="noreferrer" className="flex items-center w-auto py-4 space-x-2 uppercase btn btn-primary text-secondary">
//         <span>Gerenciar minha assinatura</span>
//         <MdOpenInNew className="text-xl" />
//       </a>
//     </div>
//   )
// }

function MyWallet() {

  const { userInfo: { userIntegration }, updateUserIntegration } = useContext(AuthContext)
  const formMethods = useForm()
  const { width, height } = useWindowSize()
  const [isOpenNotification, setIsOpenNotification] = useState({ status: false })
  const [isOpenWalletNotification, setIsOpenWalletNotification] = useState({ status: false })
  const [isOpenDisconnectModal, setIsOpenDisconnectModal] = useState({ status: false })
  const [isClearWallet, setIsClearWallet] = useState(false)

  const [status, setStatus] = useState(null)
  const { isMobile } = useMobile()

  useEffect(() => {
    if (userIntegration) {
      formMethods.reset({ isAuth: userIntegration?.isConnected })
    }

    // Set Status
    if (!userIntegration?.isConnected) {
      setStatus('DISCONNECTED')
    } else if (userIntegration?.firstB3Import) {
      setStatus('CONNECTED')
    } else {
      const dateToCompare = new Date(userIntegration?.changedOn || userIntegration?.createdOn)
      if (!dateToCompare?.getTime()) {
        setStatus('IN_PROGRESS')
        return
      }
      const lastChangesAfterOneHour = (new Date().getTime() - dateToCompare.getTime()) > 1000000 // 3600000
      if (lastChangesAfterOneHour) {
        setStatus('ERROR')
      } else {
        setStatus('IN_PROGRESS')
      }
    }
  }, [userIntegration, setStatus, formMethods])

  const submit = useCallback(async val => {
    if (!val.isAuth)
      return toast.error('Necessário autorizar o acesso dos dados pela plataforma Valor One!')
    if (userIntegration) {
      const { isConnected } = userIntegration
      const newConnectedStatus = !isConnected

      if (isConnected) {
        const onDisconnectCallback = async () => {
          try {
            await PreferencesApi.updateUserIntegrationStatus(newConnectedStatus)
            await ComDinheiroApi.removeUserB3()
            updateUserIntegration({ isConnected: newConnectedStatus, changedOn: new Date().toISOString() })
            toast.success('Desconectado da B3 com sucesso!')
          } catch (err) {
            toast.error('Não foi possível desconectar a B3!')
          }
        }
        setIsOpenDisconnectModal({ status: true, onDisconnectCallback })
      } else {
        const onSuccessCallback = async () => {
          try {
            await PreferencesApi.createUserIntegrationDocument(val.document)
            await PreferencesApi.updateUserIntegrationStatus(newConnectedStatus)
            await ComDinheiroApi.addUserB3()
            updateUserIntegration({ isConnected: newConnectedStatus, document: val.document, changedOn: new Date().toISOString() })
            openPopup()
            setStatus('IN_PROGRESS')
          } catch (err) {
            toast.error('Não foi possível conectar a B3!')
          } finally {
            setIsOpenNotification({ status: false })
          }
        }
        setIsOpenNotification({ status: true, onSuccessCallback })
      }
    } else {

      if (!val.document)
        return toast.error('Necessário preencher o campo CPF!')
      const onSuccessCallback = async () => {
        try {
          await PreferencesApi.createUserIntegrationDocument(val.document)
          updateUserIntegration({ isConnected: true, document: val.document, changedOn: new Date().toISOString() })
          openPopup()
          setStatus('IN_PROGRESS')
        } catch (error) {
          toast.error('Não foi possível conectar a B3!')
        } finally {
          setIsOpenNotification({ status: false })
        }
      }
      setIsOpenNotification({ status: true, onSuccessCallback })
    }
  }, [userIntegration, updateUserIntegration])

  const cleanWallet = useCallback(async () => {
    setIsOpenWalletNotification({ status: false })
    try {
      await ComDinheiroApi.cleanWallet()
      toast.success('Carteira foi limpa com sucesso!')
    } catch (error) {
      toast.error('Não foi possível limpar a carteira!')
    }
  }, [])

  const actionDisconnectWallet = useCallback(async () => {
    try {
      if (isClearWallet) {
        await ComDinheiroApi.cleanWallet()
        toast.success('Carteira foi limpa com sucesso!')
      }
      await ComDinheiroApi.removeUserB3()
      await PreferencesApi.updateUserIntegrationStatus(false)
      updateUserIntegration({ isConnected: false, changedOn: new Date().toISOString() })
      setIsOpenDisconnectModal({ status: false })
    } catch (error) {
      toast.error(error)
    }
  }, [isClearWallet, updateUserIntegration])
  
  async function openPopup() {
    window.open('https://b3investidor.b2clogin.com/b3investidor.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_FINTECH&client_id=03c1275b-459f-4648-85f2-c3b8b85f8f26&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fwww.investidor.b3.com.br&scope=openid&response_type=code&prompt=login', '_blank', 'popup')
  }

  function maskedCPF(cpf){
    if(cpf.includes("*")){
      return cpf
    }

    return `***.${cpf.slice(4, 7)}.${cpf.slice(8, 11)}.**`;
  }

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(submit)} className="max-w-4xl mt-3 space-y-4">
        <h4 className="text-xl font-semibold uppercase text-primary">Módulo minha carteira</h4>
        <h5 className="text-lg font-bold text-primary">Conexão com a B3</h5>
        {userIntegration?.isConnected ? <p>CPF: {maskedCPF(userIntegration?.document)} </p> :
          <div className="w-full sm:w-96">
            <label>CPF:</label>
            <MaskedInput name="document" placeholder="000.000.000-00" options={{ required: true, minLength: 14 }} errors mask="999.999.999-99" className={`${width < 442 && 'w-full'} input`} />
          </div>
        }
        <label className="flex items-start space-x-2">
          <input type="checkbox" className="mt-2 cursor-pointer accent-primary" {...formMethods.register('isAuth', { required: true })} disabled={userIntegration?.isConnected} />
          <p>Autorizo a plataforma Valor One a acessar os meus dados financeiros, do CPF por mim informado, através da API da B3, que mantém o histórico de movimentações e posições de ativos na(s) conta(s) das corretoras nas quais opero desde novembro de 2019.</p>
        </label>

        {
          ({
            CONNECTED: (
              <div className="flex items-center space-x-4">
                <MdOutlineLink className="text-4xl text-secondary" />
                <button className="btn btn-primary text-secondary" type="button" onClick={() => submit(formMethods.getValues())}>Desconectar</button>
              </div>
            ),
            DISCONNECTED: (
              <div className="flex items-center space-x-4">
                <MdOutlineLinkOff className="text-4xl text-primary" />
                <button disabled={!formMethods.formState.isValid} className={`btn ${formMethods.formState.isValid ? 'btn-primary text-secondary' : 'bg-gray-200 text-gray-500'}`} type="submit">Conectar</button>
              </div>

            ),
            IN_PROGRESS: (
              <>
                <div className="flex items-center space-x-4">
                  <MdOutlineLink className="text-4xl text-gray-400" />
                  <button disabled className="text-gray-500 bg-gray-200 btn" type="submit">Em Andamento</button>
                </div>
                <div className="flex items-start max-w-xl p-4 mt-4 space-x-2 bg-gray-200 border-2 border-gray-300 rounded-lg">
                  <div className="py-1">
                    <MdOutlineWarningAmber className="text-lg text-warning text-amber-400" />
                  </div>
                  <p className="flex-grow">
                    <b>Atenção: </b>
                    Confirmação da B3 pendente. Após a conexão ser verificada, seus ativos aparecerão no módulo Minha Carteira em até 60 minutos.
                  </p>
                </div>
              </>
            ),
            ERROR: (
              <>
                <div className="flex items-center space-x-4">
                  <MdOutlineLinkOff className="text-4xl text-gray-400" />
                  <button className="btn btn-primary text-secondary" type="button" onClick={() => submit(formMethods.getValues())}>Desconectar</button>
                </div>
                <div className="flex items-start max-w-xl p-4 mt-4 space-x-2 bg-gray-200 border-2 border-gray-300 rounded-lg">
                  <div className="py-1">
                    <MdErrorOutline className="text-lg text-red-500 text-warning" />
                  </div>
                  <p className="flex-grow">
                    <b>Erro: </b>
                    Não foi possível finalizar a conexão com a B3. Desconecte e tente novamente.
                  </p>
                </div>
              </>
            )
          })[status]
        }
        <h5 className="text-lg font-bold text-primary">Dados da sua carteira</h5>

        <p>Utilize o botão abaixo para limpar o seu consolidador de carteira, removendo todas as transações sejam elas adicionadas manualmente, por notas de corretagem ou via conexão com a B3. Se você continuar conectado com a B3 suas transações serão sincronizadas novamente no dia seguinte.</p>
        <button className="flex items-center w-auto space-x-2 uppercase btn btn-primary text-secondary" type="button" onClick={() => setIsOpenWalletNotification({ status: true })}>
          <span>Limpar minha carteira</span>
        </button>
      </form>
      <NotificationModal
        isOpen={isOpenNotification.status}
        closeModal={() => setIsOpenNotification({ status: false })}
        title={`Conectar dados da B3?`}
        isMobile={isMobile}
        className={isMobile && 'modal-content-mobile'}
        text={<div className="text-left">Ao fazer conexão com a B3, os valores de compras de ações e outros ativos de renda variável realizados
          antes de novembro de 2019 virão com preços de fechamento de 01/11/2019, NÃO SENDO RECOMENDADOS para geração de Darf
          para recolhimento de Imposto de Renda no momento da venda desses ativos. É possível, porém, corrigir manualmente essas
          informações antigas dentro do consolidador do Valor One. <br /> <br /> Conectar com a B3 e importar dados?</div>}
        style={{
          content: {
            position: 'absolute',
            background: 'rgb(255, 255, 255)',
            inset: isMobile ? 'unset' : '40px',
            borderRadius: 10,
            outline: 'none',
            padding: '20px',
            maxWidth: isMobile ? 'none' : 410,
            maxHeight: isMobile ? 'none' : 500,
            margin: !isMobile ? 'auto' : 'none',
          }
        }}
        // style={{ content: { maxWidth: 410, maxHeight: !isMobile ? 540 : 600, borderRadius: 10, margin: !isMobile ? 'auto' : `${height <= 700 ? '8% -10%' : '15% -10%'}`, padding: '20px 20px', overflowY: "auto" } }}
        successModal={() => isOpenNotification.onSuccessCallback()}
        successLabel="Conectar"
        actionButtons />

      <NotificationModal
        isOpen={isOpenWalletNotification.status}
        closeModal={() => setIsOpenWalletNotification({ status: false })}
        isMobile={isMobile}
        title={`Limpar a carteira?`}
        text={<div className="text-left">Todas as entradas da carteira serão removidas.<br /> Esta ação não pode ser desfeita.</div>}
        style={{ content: { maxWidth: isMobile ? width : 410, maxHeight: !isMobile ? 300 : 300, borderRadius: 10, margin: !isMobile ? 'auto' : `${height <= 700 ? '35% -10%' : '40% -10%'}`, padding: 35 } }}
        successModal={() => cleanWallet()}
        successLabel="Limpar"
        actionButtons />

      <NotificationModal
        isOpen={isOpenDisconnectModal.status}
        closeModal={() => setIsOpenDisconnectModal({ status: false })}
        isMobile={isMobile}
        title={`Desconectar dados da B3?`}
        style={{ content: {
          maxWidth: isMobile ? width : 400,
          maxHeight: !isMobile && 700,
          height: 'fit-content',
          borderRadius: 10,
          margin: 'auto',
          padding: 20,
          marginRight: isMobile ? -40 : "auto",
          marginLeft: isMobile ? -40 : "auto"
        } }}
      >
        <div className="flex flex-col">
          <div className="text-left mb-14">Suas novas movimentações de ativos registrados na B3 não serão mais atualizadas automaticamente. <br /> Para excluir a autorização de compartilhamento de dados de forma definitiva é necessário fazê-lo na Área do Investidor da B3.</div>
          <CheckboxBasic setIsChecked={(e) => { setIsClearWallet(e) }}>
            <span className="flex items-center ml-5 text-base">Excluir também da "Minha Carteira" as informações históricas captadas diretamente na B3.</span>
          </CheckboxBasic>
          <div className='flex flex-wrap-reverse justify-center w-full mt-10 gap-5'>
            <button onClick={() => setIsOpenDisconnectModal({ status: false })} className='btn btn-secondary text-primary'>cancelar</button>
            <button onClick={() => actionDisconnectWallet()} className={`flex gap-2 btn btn-primary text-secondary`} >desconectar</button>
          </div>
        </div>
      </NotificationModal>
    </FormProvider >

  )
}

// function Ticket() {
//   return 'Ticket'
// }

export default Settings