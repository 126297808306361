export function abs(n, type) {
  if (typeof n !== 'number')
    return null
  return (n === 0 ? '' : (n > 0 ? '+' : '-')) + format(Math.abs(n), type)
}

export function format(n, type, decimalPoints = 2, fallback) {
  if (typeof n !== 'number') {
    return fallback || null
  }

  const params = {}
  if (type === 'integer') {
    params.maximumFractionDigits = 0
  } else {
    params.minimumFractionDigits = decimalPoints
    params.maximumFractionDigits = decimalPoints
  } 
  
  if (type === 'currency') {
    params.currency = 'BRL'
  }
  let res = n.toLocaleString('pt-BR', params)

  if (type === 'percent')
  res += '%'

  return res
}

export const formatterBRL = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })

export function convertToInternationalCurrencySystem(labelValue, typeFormatted) {
  if (!labelValue) return
  const formattedNumber = (value, factor, type) => {
    const toFixed = typeFormatted === 'integer' ? 0 : 2
    const result = (Math.abs(Number(value)) / factor).toFixed(toFixed) 
    return `${result.toString().replace('.', ',')} ${type}`
  }

  const number = Math.abs(Number(labelValue))
  if (number >= 1.0e+9)
    return formattedNumber(labelValue, 1.0e+9, 'B')
  if (number >= 1.0e+6)
    return formattedNumber(labelValue, 1.0e+6, 'M')
  if (number >= 1.0e+3)
    return formattedNumber(labelValue, 1.0e+3, 'K')
  return number

}