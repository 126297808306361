export function useLocalParams(id) {

  const strParams = localStorage.getItem(id)
  const params = strParams ? JSON.parse(strParams) : {}

  return {
    params,
    updateWidgetParams: ({params}) => {
      localStorage.setItem(id, JSON.stringify(params))
    }
  }

}