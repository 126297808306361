
import { useEffect, useState } from "react"
import { MdContentCopy, MdOutlineInput } from "react-icons/md"
import Modal from "react-modal"
import { toast } from "react-toastify"
import CloseButton from "shared/CloseButton"
import Tabs from "shared/Tabs"
import { LoadingBox } from "shared/Loading"
import QuoteApi from 'api/QuoteApi'
import SymbolSelectorAutocomplete from "shared/SymbolSelectorAutocomplete"
import useWindowSize from "hooks/useWindowSize"
import { LAYOUT_BREAKPOINT_WIDTH_MOBILE } from "const"
import { useQuotationPanel } from "../context"
import { ModalTypes } from "../types"


function ModalAddItems() {

  const { state, actions } = useQuotationPanel()
  const [activeState, setActiveState] = useState('initial')
  const { width } = useWindowSize()

  const isOpen = state.modal?.type === ModalTypes.ADD_ITEMS
  const isMobile = width < LAYOUT_BREAKPOINT_WIDTH_MOBILE

  useEffect(() => {
    if (isOpen && !state.activeList) {
      toast.error('Crie um painel antes de adicionar ativos!')
      actions.closeModal()
    }
  }, [state, actions, isOpen])

  useEffect(() => {
    setActiveState(state => state !== 'initial' ? 'initial' : state)
  }, [isOpen])


  const options = [
    { Icon: MdOutlineInput, label: 'Manualmente', state: 'manual' },
    { Icon: MdContentCopy, label: 'Copiar e Colar um painel de ativos', state: 'copy_paste' },
  ]

  const close = () => actions.closeModal()

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={close}
      style={{
        content: {
          maxWidth: isMobile ? width : 648,
          margin: "auto",
          marginRight: isMobile ? -40 : "auto",
          marginLeft: isMobile ? -40 : "auto",
          
        }
      }}
    >
      <CloseButton onClick={close} />
      <div className="relative flex flex-col h-full">
        <div className="mb-5">
          <h2 className="font-bold text-xl text-primary uppercase mb-2">
            Adicionar ativos
          </h2>
          <p className={`${activeState === 'copy_paste' && 'hidden sm:block'}`}>
            Selecione os ativos para adicionar à {state.activeList?.name}
          </p>
        </div>
        {
          {
            'initial': (
              <div className="space-y-2" >
                {
                  options.map(({ Icon, label, state, disabled }) => (
                    <button 
                      className={`w-full h-20 bg-primary grid grid-cols-3 gap-2 items-center rounded-xl text-white p-4 ${disabled ? 'opacity-70' : 'cursor-pointer'}`} 
                      onClick={() => disabled || setActiveState(state)} key={state}>
                      <Icon className="justify-self-center text-3xl text-secondary" />
                      <div className="justify-self-start col-span-2 text-left">{label}</div>
                    </button>
                  ))
                }
              </div>
            ),
            'manual': <AddItemsManual onCancel={close} closeModal={close} />,
            'copy_paste': <AddItemsCopyPaste onCancel={() => setActiveState('initial')} closeModal={close} />
          }[activeState]
        }
      </div>
    </Modal >
  )
}

function AddItemsManual({ onCancel, closeModal }) {
  const { state, actions } = useQuotationPanel()
  const [symbols, setSymbols] = useState(state.activeList?.symbols)

  const save = async () => {
    try {
      await actions.updateList(state.activeList, { symbols })
      toast.success(`O painel ${state.activeList.name} foi alterada com sucesso!`)
      closeModal()
    } catch (error) {
      toast.error('Não foi editar o painel')
    }
  }

  return (
    <>
      <SymbolSelectorAutocomplete onChange={setSymbols} symbolsList={symbols} />
      <div className="flex justify-center space-x-2 mt-3">
        <button className="btn btn-secondary" onClick={onCancel}>Cancelar</button>
        <button className="btn btn-primary" onClick={save}>Adicionar</button>
      </div>
    </>
  )

}

function AddItemsCopyPaste({ onCancel, closeModal }) {
  const { state, actions } = useQuotationPanel()
  const [list, setList] = useState('')
  const [listFormatted, setListFormatted] = useState(null)
  const [tabIndex, setTabIndex] = useState(0)
  const [loading, setLoading] = useState(false)

  const tabs = [{ name: "INSIRA" }, { name: "CONFIRA" }, { name: "FINALIZE" }]
  const tableTitle = [{ name: "Código" }, { name: "Nome" }, { name: "Origem" }]

  async function createList() {
    try {
      setLoading(true)
      const listArray = list.split('\n')
      await Promise.all(listArray.map(async (e) => {
        const codeFormatted = e.replace(/[!@#$%¨&*()'"\s]/g, '').trim()
        return await QuoteApi.autocompleteSuggestion(codeFormatted)
      }))
        .then(data => {
          const listSymbol = data.flatMap(e => e && e[0]).filter(e => e)
          setListFormatted(listSymbol)
        })

      setTabIndex(tabIndex + 1)
      setLoading(false)

    } catch (error) {
      console.error(error)
      toast.error('Não foi possível editar o painel')
      setLoading(false)
    }

  }

  const save = async () => {
    try {
      setTabIndex(tabIndex + 1)
      const newSymbols = await listFormatted.map((e) => {
        return { origin: e.originId, symbol: e.symbolCode }
      })
      const symbols = await [
        ...state.activeList.symbols.map(({ origin, symbol }) => ({ origin, symbol })),
        ...newSymbols
      ].filter((e, i, array) => {
        const itemString = JSON.stringify(e)
        return i === array.findIndex((obj) => JSON.stringify(obj) === itemString)
      })
      actions.updateList(state.activeList, { symbols })
      toast.success(`O painel ${state.activeList.name} foi alterada com sucesso!`)
      closeModal()
    } catch (error) {
      setTabIndex(tabIndex - 1)
      toast.error('Não foi possível editar painel')
    }
  }

  return (
    <>
      <div className="flex flex-col gap-2">
        <Tabs 
          tabs={tabs}
          onChange={({ tab, index }) => setTabIndex(index)}
          actionClick={false}
          activeTabIndex={tabIndex}
          parentClass="justify-between px-4"
          tabClass={`cursor-text`} />
        {
          {
            0: loading ?
              <div className="p-2" style={{ height: '372px' }}>
                <LoadingBox />
              </div> :
              <textarea
                onChange={e => setList(e.target.value)}
                value={list}
                autoFocus={true}
                placeholder="Cole ou digite uma lista de ativos (um código em cada linha)"
                rows="15"
                className="p-2 border rounded-xl resize-none mini-scrollbar"
              />,
            1: <div className="border-2 rounded-xl overflow-y-auto mini-scrollbar" style={{ height: '372px' }}>
              <table className="w-full">
                <thead className="bg-gray-300 text-gray-700">
                  <tr className="text-left">
                    {tableTitle.map(({ name }, index) => (
                      <th 
                        key={name + index}
                        className="min-w-min w-28 even:py-2 odd:p-2 last:hidden sm:last:table-cell sm:last:text-right font-normal"
                      >
                        {name}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {listFormatted && listFormatted.map((symbol, index) =>
                  (symbol && <tr key={symbol.symbolCode + "<" + index + ">" + symbol.securityDesc} className="p-1 border-b-2">
                    <td className="pl-2 text-left">{symbol.symbolCode}</td>
                    <td className="text-left">{symbol.securityDesc}</td>
                    <td className="hidden sm:table-cell sm:text-right pr-2">{symbol.origin}</td>
                  </tr>)
                  )}
                </tbody>
              </table>
            </div>,
            2: <div className="p-2" style={{ height: '372px' }}>
              <LoadingBox />
            </div>
          }[tabIndex]
        }
      </div>
      <div className="flex justify-center space-x-2 mt-3">
        {
          tabIndex === 0 ?
            <>
              <button className="btn btn-secondary" onClick={onCancel}>Cancelar</button>
              <button className="btn btn-primary text-secondary" onClick={() => createList()} disabled={!list}>Próximo</button>
            </> :
            <>
              <button className="btn btn-secondary" onClick={() => setTabIndex(tabIndex - 1)}>Voltar</button>
              <button className="btn btn-primary text-secondary" onClick={save}>Finalizar</button>
            </>
        }
      </div>
    </>
  )

}

export default ModalAddItems