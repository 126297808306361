import { usePermission } from "hooks/usePermission"
import { ORIGINS } from "const"
import { useEffect, useMemo, useState } from "react"
import { toast } from "react-toastify"
import NewsApi from "api/NewsApi"

function useNews({ keyword = [], companies = [], perPage = 10 }) {

  const permission = usePermission('News')
  const [loading, setLoading] = useState()
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  
  const origin = useMemo(() => {
    if (!permission) return null
    return ORIGINS.filter(origin =>
      permission?.childrens?.some((permission) => permission.key === origin.key)
    ).map(x => x.key)
  }, [permission])


  useEffect(() => {
    if (!origin) return

    const fetchNews = async () => {
      setLoading(true)
      try {
        const response = await NewsApi.fetchNewsList({
          origin,
          keyword,
          companies,
          perPage
        })

        if (!response) {
          setData([])
          throw new Error(`Erro: ${response.status}`)
        }
        setError(null)
        setData(response)
      } catch (error) {
        console.error(error)
        toast.error('Falha ao buscar notícias!')
        setError(error)
        setData(null)
      } finally {
        setLoading(false)
      }
    }

    fetchNews()
  }, [keyword, companies, perPage, origin])

  return { data, loading, error }

}

export default useNews