import { useEffect, useContext } from "react"
import PreferencesContext from "context/PreferencesContext"
import { useQuotationPanel } from '../context'
import { isEqual } from "lodash"

function useFavoriteList() {
  const { events: preferencesEvents } = useContext(PreferencesContext)
  const { state, actions } = useQuotationPanel()

  useEffect(() => {
    const callback = (interestList) => {
      const favList = state.lists.find(list => interestList.id === list.id)
      if (!favList) 
        return
      if (isEqual(favList.symbols, interestList.symbols)) {
        return
      }
      actions.updateFavoriteList(interestList)
    }
    preferencesEvents.on('changeInterestList', callback)
    return () => preferencesEvents.off('changeInterestList', callback)
  }, [state, actions, preferencesEvents])

}

export default useFavoriteList