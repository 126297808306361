import { useCallback, useEffect, useRef, useState } from "react"
import { useDrop, useDrag } from "react-dnd"
import { toast } from "react-toastify"

export function useDroppableList(
  { originalData, onSave, type = "ROW", uniqueId = "id" }, 
  dependencies = []
) {

  const [data, setData] = useState([...originalData])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setData([...originalData]), [originalData, ...dependencies])

  const findItem = useCallback((id) => {
    const item = data.filter((c) => c[uniqueId] === id)[0]
    return {
      item,
      index: data.indexOf(item),
    }
  }, [data, uniqueId])

  const moveItem = useCallback((id, atIndex) => {
    const { item, index } = findItem(id)
    const newData = [...data]
    newData.splice(index, 1)
    newData.splice(atIndex, 0, item)
    setData(newData)
  }, [findItem, data])

  const saveMovedRow = useCallback(async () => {
    try {
      await onSave(data)
    } catch (error) {
      setData(originalData)
      toast.error('Não foi possível alterar a lista.')
    }
  }, [data, onSave, originalData])

  const [, drop] = useDrop(() => ({ accept: type }))

  return { data, type, drop, findItem, moveItem, saveMovedRow }

}

export function useDraggableListItem(id, { findItem, moveItem, saveMovedRow, type }) {

  const originalIndex = findItem(id).index
  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type,
    item: { id, originalIndex },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      const { id: droppedId, originalIndex } = item
      const didDrop = monitor.didDrop()
      if (!didDrop) {
        moveItem(droppedId, originalIndex)
      } else {
        saveMovedRow()
      }
    },
  }), [id, originalIndex, moveItem])

  const [, drop] = useDrop(() => ({
    accept: type,
    hover({ id: draggedId }) {
      if (draggedId !== id) {
        const { index: overIndex } = findItem(id)
        moveItem(draggedId, overIndex)
      }
    },
  }), [findItem, moveItem])

  const ref = useRef(null)
  const dropPreviewRef = preview(drop(ref))


  return { isDragging, drag, drop, preview, dropPreviewRef }
}