import { createContext, useCallback, useEffect, useMemo, useState } from "react"
import { useTimer } from "hooks/useTimer"
import NewsApi from "api/NewsApi"
import PreferencesApi from "api/PreferencesApi"
import CompaniesApi from "api/CompaniesApi"
import { toast } from "react-toastify"
import { NEWS_TABS, ORIGINS } from "const"
import { usePermission } from "hooks/usePermission"
import { cloneDeep, flatten, isEqual, uniq } from 'lodash'
import ComDinheiroApi from 'api/ComDinheiroApi'

export const NewsContext = createContext({
  newsList: [],
  filterValue: null,
  tabIndex: 0,
  permission: null,
  isFilterModalOpen: null,
  tabs: [],
  origins: [],
  selectedNews: [],
  isFiltersValueClean: false,
  updateFilters: async (filters) => { },
  selectNews: async () => { },
  matchTopicFilterWalletPosition: () => { },
  setIsFilterModalOpen: (isOpen) => {},
  updateTabIndex: () => { },
  clearFilters: () => { },
  loadMore: () => { },
})

function handleFilterWithPermissions(filter, availableOrigins) {
  const newFilter = cloneDeep(filter), keys = Object.keys(newFilter.origin)
  for (const key of keys) {
    newFilter.origin[key] = newFilter.origin[key] && availableOrigins.some(o => o.key === key)
  }
  if (Object.values(newFilter.origin).every(v => !v)) {
    availableOrigins.forEach(({key}) => {
      newFilter.origin[key] = true
    })
  }
  return newFilter
}

export function NewsContextProvider({ children, onSelectNews, widgetProps }) {
  const [newsList, setNewsList] = useState([])
  const [selectedNews, setSelectedNews] = useState(null)
  const [tabIndex, setTabIndex] = useState(widgetProps?.params.tabIndex || 0)
  // const [origins, setOrigins] = useState(ORIGINS)
  const timer = useTimer(60000)
  const permission = usePermission('News')
  const [filterValue, setFilterValue] = useState(null)
  const newsPerPage = useMemo(() => new Map(), [])
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false)
  const origins = useMemo(
    () => ORIGINS.map(origin => ({ ...origin, isActive: !!permission?.childrens?.find(p => p.key === origin.key) })),
    [ORIGINS, permission?.childrens]
  )
  const availableOrigins = useMemo(() => origins.filter(x => x.isActive), [origins])
  const isFiltersValueClean = useMemo(() => {
    return !(filterValue?.companies?.length || filterValue?.categories?.length || filterValue?.keyword?.length || filterValue?.isWalletSyncActive)
  }, [filterValue])


  const getRelatedNews = useCallback(async (news) => {
    if (news.related?.length) return news.related
    const params = { origin: availableOrigins.map(x => x.key) }
    if (news.companies?.length) {
      params.companies = news.companies
    }
    try {
      const related = await NewsApi.fetchNewsList({
        ...params,
        perPage: 6,
        page: 1
      })

      return related.filter(x => x.link !== news.link)
    } catch (error) {
      console.error(error)
      return []
    }
  }, [origins])

  const selectNews = useCallback(async (news, options = { dispatchEvent: true }) => {
    news.wasRead = true
    try {
      const [related, symbols] = await Promise.all([getRelatedNews(news), getSymbols(news)])
      news.related = related
      news.symbols = symbols
      setSelectedNews(news)
      if (onSelectNews && options.dispatchEvent)
        onSelectNews(news)

    } catch (error) {
      console.error('Erro ao selecionar notícia!', error)
    }
  }, [onSelectNews, setSelectedNews, getRelatedNews])

  const fetchNews = useCallback(async (page = 1) => {
    if (!filterValue || !availableOrigins.length) return
    try {
      const filter = await getFilters()
      const response = await NewsApi.fetchNewsList({ ...filter, page, perPage: 50 })
      if (isEqual(response.map(x => x.link), newsPerPage.get(page)?.map(x => x.link))) return
      newsPerPage.set(page, response)
      const val = flatten([...newsPerPage.values()])
      setNewsList(val)

      if (!selectedNews || val.every(r => r.link !== selectedNews?.link))
        selectNews(response[0], { dispatchEvent: false })
    } catch (error) {
      console.error(error)
      toast.error('Falha ao buscar notícias!')
      setNewsList(null)
    }
  }, [setNewsList, filterValue, selectedNews, setSelectedNews, tabIndex, selectNews, timer, origins])

  const loadFilter = useCallback(async () => {
    try {
      let filters = await PreferencesApi.getNewsFilters()
      // Criar filtro caso não exista
      if (filters === null) {
        const newFilters = { origin: ORIGINS.map(x => x.key).reduce((ac, k) => ({ ...ac, [k]: permission?.childrens?.some(p => p.key === k) }), {}) }
        PreferencesApi.updateNewsFilters(newFilters)
        setFilterValue(newFilters)
        return
      }
      setFilterValue(handleFilterWithPermissions(filters, availableOrigins))
    } catch (error) {
      console.error(error)
    }
  }, [setFilterValue])

  async function updateFilters(value) {
    newsPerPage.clear()
    const filters = { ...filterValue, ...value }
    setFilterValue(filters)
    setNewsList(null)
    try {
      const x = handleFilterWithPermissions(filters, availableOrigins)
      await PreferencesApi.updateNewsFilters(x)
    } catch (error) {
      console.error(error)
    }
  }

  const tabs = NEWS_TABS.map((tab) => ({
    ...tab,
    isActive: tab.isActive || permission?.childrens?.some((permission) => permission.key === tab.key)
  }))

  useEffect(() => { loadFilter() }, [loadFilter])
  useEffect(() => { fetchNews() }, [fetchNews, filterValue, tabIndex])

  function updateTabIndex(index) {
    newsPerPage.clear()
    setTabIndex(index)
    updateParams({ tabIndex: index })
    setNewsList(null)
  }

  function clearFilters() {
    setFilterValue(widgetProps?.defaults.params.filters)
  }

  async function getSymbols(news) {
    if (news.symbols?.length) return news.symbols
    if (!news.companies) return []

    try {
      const response = await Promise.all(news.companies.map(CompaniesApi.search))
      const symbols = response.reduce((ac, x) => [...ac, ...(x[0]?.symbols.filter(s => ac.every (y => y !== s)) || [])], [])
      if (!symbols.length) return null
      return symbols
    } catch (error) {
      console.error(error)
      return null 
    }
  }

  function updateParams(value) {
    const newParams = { ...widgetProps.params, ...value }
    widgetProps.updateWidgetParams({ id: widgetProps.id, params: newParams })
  }

  function loadMore() {
    const nextPage = Math.max(...newsPerPage.keys()) + 1
    if (nextPage < 1) return
    fetchNews(nextPage)
  }

  async function getFilters() {
    const filter = { ...filterValue, timer: timer?.toJSON(), origin: Object.entries(filterValue.origin).filter(([k, v]) => v).map(([k, v]) => k) }

    switch (tabIndex) {
      case 0:
        filter.companies = []
        filter.keyword = []
        filter.categories = []
        break
      case 1:
        if (filter.isWalletSyncActive) {
          const { companies = [] } = (await ComDinheiroApi.getWalletPosition() || {})
          filter.companies = [...companies, ...filter.companies]
        }
        break
      // case 2:
      //   filter.keyword = []
      //   filter.categories = []
      //   filter.companies = await filterWalletPosition()
      //   break
      default: 
        return filter
    }

    if (filter.companies?.length)
      filter.companies = uniq((filter.companies || []).map(x => x.name))
    return filter
  }

  function matchTopicFilterWalletPosition(assets) {
    const response = []
    if (assets.some(x => x.classe === 'ETF' || x.classe === 'Fundos')) {
      response.push('Fundos de ações e ETFs', 'Fundos de Renda Fixa', 'Fundos multimercados', 'Mercados internacionais')
    }
    if (assets.some(x => x.classe === 'Fundos')) {
      response.push('Fundos de ações e ETFs')
    }
    if (assets.some(x => x.classe === 'Tesouro Direto')) {
      response.push('Tesouro Direto')
    }
    if (assets.some(x => x.classe === 'FII')) {
      response.push('Fundos imobiliários', 'Imóveis')
    }
    if (assets.some(x => x.classe === 'Ações')) {
      response.push('Ações Brasil')
    }
    if (assets.some(x => x.classe === 'Futuros')) {
      response.push('Macroeconomia')
    }
    return response
  }

  return (
    <NewsContext.Provider value={{
      newsList,
      selectedNews,
      filterValue,
      origins,
      tabIndex,
      permission,
      isFilterModalOpen,
      tabs,
      isFiltersValueClean,
      setIsFilterModalOpen,
      matchTopicFilterWalletPosition,
      updateTabIndex,
      updateFilters,
      clearFilters,
      selectNews,
      loadMore
    }}>
      {children}
    </NewsContext.Provider>
  )

}