
import MainLayout from 'layout/MainLayout'
import { Routes, Route } from 'react-router-dom'
import NotFound from "pages/NotFound"
import Workspace from "pages/Workspace"
import Alerts from "pages/Alerts"
import Contract from "pages/Contract"
import HealthCheck from "pages/HealthCheck"
import { TradingSummaryPage as TradingSummary } from "shared/widgets/TradingSummary"
import { MarketMapPage as MarketMap } from "shared/widgets/MarketMap"
import QuickQuote from "shared/widgets/QuickQuote"
import { GraphicsPage as Graphics } from "shared/widgets/Graphics"
import { FixedIncomeCalculatorPage as FixedIncomeCalculator } from "shared/widgets/FixedIncomeCalculator"
import { SimulatorPage as Simulator } from "shared/widgets/Simulator"
import { GenericPage as Generic } from 'shared/widgets/Generic'
import { QuotationPanelPage as QuotationPanel } from "shared/widgets/QuotationPanel"
import { NewsPage as News } from 'shared/widgets/News'
import SearchResult from 'pages/SearchResult'
import { WalletPage as Wallet } from 'shared/widgets/Wallet'
import { RecommendationPage as Recommendation } from 'shared/widgets/Recommendation'
import { FundsPage as Funds } from 'shared/widgets/Funds'
import Settings, { navigationItems as SettingsNavigation } from 'pages/Settings'
import { usePermissionValidation } from 'hooks/usePermission'
import Plans from 'pages/Plans'
import { PageUsageGuide } from 'pages/UsageGuide'
import { SessionInactive } from 'pages/SessionInactive'
import { AlertMonitor } from 'pages/AlertMonitor'
import { FundsRankingMethodology } from 'pages/FundsRankingMethodology'

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route path="workspace" element={<Workspace />} />
        <Route path="alerts" element={guard('Alerts', <Alerts />)} />
        <Route path="/alert-monitor" element={guard('Alerts', <AlertMonitor />)} />
        <Route path="/noticias" element={guard('News', <News />)} />
        <Route path="/painel-de-cotacoes" element={guard('QuotationPanel', <QuotationPanel />)} />
        <Route path="/resumo-da-bolsa" element={guard('TradingSummary', <TradingSummary />)} />
        <Route path="/mapa-de-mercado" element={guard('MarketMap', <MarketMap />)} />
        <Route path="/cotacao-rapida" element={guard('QuickQuote', <QuickQuote />)} />
        <Route path="/analise-grafica" element={guard('Graphics', <Graphics />)} />
        <Route path="/calculadora-renda-fixa" element={guard('FixedIncomeCalculator', <FixedIncomeCalculator />)} />
        <Route path="/simulador" element={guard('Simulator', <Simulator />)} />
        <Route path="/minha-carteira" element={guard('Wallet', <Wallet />)} />
        <Route path="/recomendacoes-e-analises" element={guard('Recommendation', <Recommendation />)} />
        <Route path="/fundos" element={guard('Funds', <Funds />)} />
        <Route path="/modulo/:id" element={guard('Generic', <Generic />)} />
        <Route path="/planos" element={<Plans />} />
        <Route path="/guia" element={guard('GuiaDeUso', <PageUsageGuide />)} />
        <Route path="/metolodogia-ranking-fundos-valor" element={<FundsRankingMethodology />} />
        <Route path="/pesquisa" element={<SearchResult />} />
        <Route path="/configuracoes" element={<Settings />}>
          {SettingsNavigation.map(item => <Route key={'settings_nav_' + item.url} path={item.url} element={<item.Element />} />)}
        </Route>
        <Route path="*" element={<NotFound />} />
      </Route>
      <Route path="/sessao/:status" element={<SessionInactive />} />
      <Route path="/contrato" element={<Contract />} />
      <Route path="/healthcheck" element={<HealthCheck />} />
    </Routes>
  )
}

function guard(permissionKey, el) {
  return <Guard permissionKey={permissionKey} el={el} />
}

function Guard({ permissionKey, el }) {
  const { validatePermissionAndRedirect } = usePermissionValidation(permissionKey)
  return validatePermissionAndRedirect() || el
}

export default AppRoutes
