import { useState } from "react"
import { useContext } from "react"
import { NewsContext } from "context/NewsContext"
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs'

import useSymbol from "hooks/useSymbol"
import Delay from "shared/Delay"
import { format } from "helpers/numberHelper"
import { BtnToggleMyList } from "shared/MyList"

import { Keys } from "const"

function RelatedSymbols() {
  const { selectedNews } = useContext(NewsContext)
  const [isVisible, setIsVisible] = useState(true)

  if (!selectedNews?.symbols) return

  if (!isVisible) return (
    <div className="rounded-full border cursor-pointer inline-block p-3" title="Mostrar ações relacionadas" onClick={() => setIsVisible(true)}>
      <BsChevronRight className="text-primary font-bold" />
    </div>
  )

  return (
    <div className="border rounded p-3 relative mb-2">
      <div className="flex items-center space-x-2">
        <BsChevronLeft className="cursor-pointer text-font-bold" title="Esconder ações relacionadas" onClick={() => setIsVisible(false)} />
        <h1 className="uppercase text-primary text-lg font-bold">Ações relacionadas</h1>
      </div>
      <div className="overflow-auto mini-scrollbar-x">
        <div className="space-x-2 flex flex-nowrap my-2">
          {selectedNews.symbols.map((x, i) => <RelatedSymbolsDetail key={'related-symbol-' + i} symbol={x} origin={2} />)}
        </div>
      </div>
    </div>
  )

}

function RelatedSymbolsDetail({ symbol, origin }) {

  const data = useSymbol(symbol, origin)

  if (!data) return
  const textColor = data && (data[Keys.VAR] === 0 ? 'text-gray-500' : data[Keys.VAR] > 0 ? 'text-profit' : 'text-loss')
  const bgColor = data && (data[Keys.VAR] === 0 ? 'bg-gray-500' : data[Keys.VAR] > 0 ? 'bg-profit' : 'bg-loss')

  const lastDate = data[Keys.DULTCOT] && new Intl.DateTimeFormat('pt-BR', {
    month: '2-digit',
    day: '2-digit'
  }).format(Date.parse(data[Keys.DULTCOT]))

  return (
    <div className="flex rounded border p-2 flex-nowrap items-center space-x-2">
      <div className="whitespace-nowrap">
        <div className="font-bold text-sm opacity-80 flex items-center space-x-1">
          <span>{symbol}</span>
          <Delay item={data} className="flex-none w-3" />
        </div>
        <div className="font-semibold opacity-70 text-xs">
          R$ {format(data[Keys.ULTCOT], 'currency')}
        </div>
      </div>
      {
        typeof data[Keys.VAR] === 'number' ? (
          <div className={`whitespace-nowrap font-semibold self-stretch text-sm flex items-center p-1 rounded ${textColor} ${bgColor} bg-opacity-10`}>
            {format(data[Keys.VAR], 'percent')}
          </div>
        ) : (
          lastDate && (
            <div className={`whitespace-nowrap font-semibold self-stretch text-xs flex items-center `}>
              {lastDate}
            </div>
          )
        )
      }
      <div className="">
        <BtnToggleMyList symbol={symbol} origin={origin} />
      </div>
    </div>
  )

}

export default RelatedSymbols