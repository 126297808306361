import Price from "shared/Price"
import { ICONS_MAP, LAYOUT_BREAKPOINT_WIDTH, LAYOUT_BREAKPOINT_WIDTH_MD } from "const"
import { dateStringToLocaleString } from "helpers/dateHelper"
import Modal from "react-modal"
import { MdClose, MdArrowBackIos } from "react-icons/md"
import useTitle from "hooks/useTitle"
import useWindowSize from "hooks/useWindowSize"
import Loading from "shared/Loading"
import { SearchProvider, useSearch } from "context/SearchContext"
import useCompanies from "hooks/fetch/useCompanies"
import useNews from "hooks/fetch/useNews"
import { useState } from "react"
import { useMemo } from "react"
import { useNavigate } from "react-router-dom"

function SearchResult() {

  useTitle('Busca')
  const navigate = useNavigate()

  return (
    <SearchProvider>
      <section className="p-4 h-full overflow-auto">
        <div className="bg-white min-h-full rounded p-4">
          <div style={{ maxWidth: LAYOUT_BREAKPOINT_WIDTH_MD }}>
            <div className="flex items-center space-x-2 mb-4">
            <button onClick={() => navigate('/')}>
                <MdArrowBackIos className="text-gray-900" />
              </button>
              <h1 className="text-xl text-primary font-bold uppercase">Busca</h1>
            </div>
            {/* <QuotationResults /> */}
            <CompaniesResult />
            <hr className="my-4" />
            <NewsResults />
          </div>
        </div>
      </section>
    </SearchProvider>
  )
}

const SectionTitle = ({ title }) => (
  <h2 className="text-lg text-primary font-semibold mb-2 uppercase">{title}</h2>
)

function validateFetch(fetchResult) {
  const { data, loading, error } = fetchResult
  const NoResultsFeedback = () => <div className="text-sm">Nenhum resultado encontrado.</div>
  const LoadingFeedback = () => <Loading className="ml-3" size="30" />
  const ErrorFeedback = () => (
    <div className="text-sm">
      Não foi possível realizar a busca, tente novamente.
    </div>
  )
  if (loading) return <LoadingFeedback />
  if (error) return <ErrorFeedback />
  if (!data?.length) return <NoResultsFeedback />
  return null

}

function CompaniesResult() {

  const { searchText, isCompany } = useSearch()
  const companies = useCompanies({ searchText, exactlyMatch: isCompany })

  const ComponentBody = () => (
    validateFetch(companies) || companies.data?.map(({ name, title, symbols }) => (
      <div className="pb-2 mb-2 px-3 cursor-pointer hover:bg-[#F6F6F6]" key={'cpny_' + name}>
        <div className="flex space-x-1 items-baseline mb-2">
          <h2 className="text-sm font-semibold">{name}</h2>
          <h3 className="font-semibold opacity-50 text-xs">{title}</h3>
        </div>
        <div className="flex space-x-2">
          {
            symbols.map(symbol => (
              <div className="flex space-x-1 text-xs" key={'sbl_' + symbol}>
                <h3 className="font-semibold">{symbol}</h3>
                <Price
                  symbol={symbol}
                  origin={2}
                  containerClass="flex space-x-1 items-center"
                  varClass="text-xs"
                />
              </div>
            ))
          }
        </div>
      </div>
    ))
  )

  return (
    <>
      <SectionTitle title="Empresas" />
      <ComponentBody />
    </>
  )
}


function NewsResults() {

  const { searchText, isCompany } = useSearch()
  const newsFetchParams = useMemo(() => ({
    keyword: !isCompany ? [searchText] : [],
    companies: isCompany ? [searchText] : [],
  }), [searchText, isCompany])
  const news = useNews(newsFetchParams)
  const [selectedNews, setSelectedNews] = useState(null)

  const ComponentBody = () => (
    validateFetch(news) || news.data.map(news => (
      <div key={news.link} className="cursor-pointer hover:bg-[#F6F6F6] flex space-x-3 p-3 items-center" onClick={() => setSelectedNews(news)}>
        <img src={ICONS_MAP.get(news.origin)} className={`rounded-full w-5 h-5`} alt={news.origin} />
        <div className="space-y-1">
          <div className="text-sm font-semibold leading-tight">
            {news.title}
          </div>
          <p className="text-xs text-gray-500">
            {dateStringToLocaleString(news.publicationDate)}
          </p>
        </div>
      </div>
    ))
  )

  return (
    <>
      <SectionTitle title={`Notícias com "${searchText}"`} />
      <ComponentBody />
      <ModalShowNews selectedNews={selectedNews} closeModal={() => setSelectedNews(null)} />
    </>
  )

}


function ModalShowNews({ selectedNews, closeModal = () => { } }) {

  const { width } = useWindowSize()
  const isMobile = width < LAYOUT_BREAKPOINT_WIDTH

  return (
    <Modal
      isOpen={selectedNews}
      onRequestClose={closeModal}
      style={{ content: { padding: 0, maxWidth: 648, margin: "auto", borderRadius: '12px', inset: isMobile ? '5px' : '40px' } }}
    >
      <div className="relative h-full overflow-hidden">
        <button className="sticky float-right right-2 top-2 text-xl text-primary" title="Fechar" onClick={closeModal}>
          <MdClose />
        </button>
        {selectedNews?.link ? (
          <iframe src={selectedNews.link} className="w-full h-[97%] px-8 mini-scrollbar" title={selectedNews.title} />
        ) : (
          <div className="text-grey-400 text-sm p-2">Unavailable</div>
        )}
      </div>
    </Modal>
  )
}


export default SearchResult