import { useContext } from "react"
import { NewsContext } from "context/NewsContext"
import { ICONS_MAP } from "const"
import { dateStringToLocaleString } from "helpers/dateHelper"

function RelatedNews() {

  const { selectedNews, selectNews } = useContext(NewsContext)

  return (
    <div className="b-1 rounded">
      <h3 className="text-lg text-primary font-bold uppercase my-2">Notícias Relacionadas</h3>
      {
        selectedNews?.related?.length ? (
          selectedNews.related.map((news, index) => (
            <div key={`news-${index}`} className={`cursor-pointer hover:bg-[#F6F6F6] flex space-x-3 p-2 items-center ${selectedNews?.link === news.link && "bg-[#EDEDED]"}`} onClick={() => selectNews(news)}>
              <img src={ICONS_MAP.get(news.origin)} className={`rounded-full w-5 h-5`} alt={news.origin} />
              <div className="space-y-1">
                <div className={`text-sm font-semibold leading-tight ${selectedNews?.link !== news.link && news.wasRead && 'text-[#AAAAAA]'}`}>
                  {news.title}
                </div>
                <p className="text-xs text-gray-500 font-semibold">
                  {dateStringToLocaleString(news.publicationDate)}
                </p>
              </div>
            </div>
          ))
        ) : (
          <p className="my-2 text-gray-500 text-sm">Nenhuma notícia relacionada.</p>
        )
      }
    </div>
  )
}

export default RelatedNews