import { createContext, useEffect, useState, useMemo, useCallback } from "react"
import QuoteApi from "api/QuoteApi"
import PanelInvestApi from "api/PanelInvestApi"
import { Keys } from "const"

export const AlertMonitorContext = createContext({
  listsPanel: [],
  activeList: [],
  listsPanelDefault: [],
  updateListsPanel: (params, list) => {},
  selectedActiveList: (list) => {},
  saveLists: (list) => {},
  cancelUpdateList: () => {},
  editConfigs: null,
  setEditConfigs: (boolean) => {},
  restoreDefault: () => {}
})

export function AlertMonitorProvider({ children }) {

  const [ listsPanel, setListsPanel ] = useState([])
  const [ listsPanelDefault, setListsPanelDefault ] = useState([])
  const [ activeList, setActiveList ] = useState([])
  const [ editConfigs, setEditConfigs ] = useState(false)

  const configItems = useMemo(() => [
    { id: Keys.VAR, firstText: 'Variações relevantes no dia: abaixo de', lastText: 'ou acima de' },
    { id: Keys.VAR1MES, firstText: 'Variações relevantes nos últimos 30 dias: abaixo de', lastText: 'ou acima de' }

  ], [])

  const listsDefault = useMemo(() => [
    { id: 1, name: 'Minha carteira' },
    { id: 2, name: 'Favoritos' },
  ], [])


  async function fetchLists() {
    const response = await Promise.all(listsDefault.map(async (list) => {
      const res = await PanelInvestApi.getListId({id: list.id}).then((data) => {
        const updateData = data.map((el) => {
          const configs = updatedConfigItems(el.panelInvestDatas)
          return { ...el, panelInvestDatas: configs}
        })
        return updateData
      })
      return { ...list, ...res[0] }
    }))
    setListsPanel(response)
    setListsPanelDefault(response)
  }

  async function saveLists(list) {
    const body = list ? list : listsPanel.find(({ id }) => id === activeList.id)
    const updateLists = listsPanel.map((l) => l.id === list?.id ? list : l)
    await PanelInvestApi.updateLists(body).then(() => {
      setListsPanel(updateLists)
      setListsPanelDefault(updateLists)
    })
  }

  async function selectedActiveList(list) {
    if (!list) return
    if (list.panelInvestSymbols.length > 0) {
      const res = await getFieldsList(list.panelInvestSymbols)
      setListsPanel((lists) => {
        return lists.map((item) => {
          if (item.id === list.id) return { ...item, panelInvestSymbols: res }
          return item
        })
      })
      setActiveList({ ...list, panelInvestSymbols: res })
      return
    }
    setActiveList(list)
  }

  const getFieldsList = useCallback(async (list) => {
    const mapFields = {}
    const response = await Promise.all(list.map(async (e) => {
      const res = await QuoteApi.marketIndicators(e.origin, e.symbol, `${Keys.NOME},${Keys.FONTE}`)
      if (!res) return { ...e }
      res[0]?.fieldsMap.forEach((item) => {
        if (!item.value) return
        if (item.fieldId === Keys.NOME) mapFields['name'] = item.value
        if (item.fieldId === Keys.FONTE) mapFields['nameOrigin'] = item.value
      })
      return {...e, ...mapFields }
    }))
    return response
  },[])

  function updateListsPanel({ params, list = null }) {
    setListsPanel((lists) => {
      return lists.map((item) => {
        if (list && params) return item.id === list.id ? { ...list, ...params } : item
        else if (params) return item.id === activeList.id ? { ...item, ...params } : item
        else return item
      })
    })
  }

  function updatedConfigItems(list) {
    const configContet = configItems.map((item) => {
      const res = list.find((e) => e.config === item.id)
      return { ...item, ...res }
    })
    return configContet
  }

  function cancelUpdateList() {
    setListsPanel(listsPanelDefault)
  }

  async function restoreDefault() {
    const listUpdateFind = listsPanel.find(({painel}) => activeList.painel === painel) 
    const listUpdate = {
      ...listUpdateFind,
      flgEnabled: true,
      panelInvestDatas: listUpdateFind.panelInvestDatas.map((config) => {
        return { ...config, flgEnabled: true, numberAbove: config.config === 7687 ? 3 : 10, numberBelow: config.config === 7687 ? -3 : -10 }
      }),
      panelInvestSymbols: listUpdateFind.panelInvestSymbols.map((symbol) => ({ ...symbol, flgEnabled: true }))
    }
    setListsPanel((lists) => lists.map((list) => list.painel === activeList.painel ? listUpdate : list))
    
    // return await PanelInvestApi
    //   .updateLists(listUpdate)
    //   .then(() => {
    //     setListsPanel((lists) => lists.map((list) => list.painel === listUpdate.painel ? listUpdate : list))
    //     setListsPanelDefault((lists) => lists.map((list) => list.painel === listUpdate.painel ? listUpdate : list))
    //   })
  }

  useEffect(() => {
    fetchLists()
  }, [])
  
  useEffect(() => {
    if (!activeList.id) selectedActiveList(listsPanel[0])
  }, [listsPanel])

  return (
    <AlertMonitorContext.Provider
      value={{
        listsPanel,
        activeList,
        listsPanelDefault,
        updateListsPanel,
        selectedActiveList,
        saveLists,
        cancelUpdateList,
        editConfigs,
        setEditConfigs,
        restoreDefault
      }}
    >
      {children}
    </AlertMonitorContext.Provider>
  )
}

