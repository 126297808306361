import { NewsContext } from "context/NewsContext"
import { useContext } from "react"
import Modal from "react-modal"
import { MdClose } from "react-icons/md"
import useWindowSize from "hooks/useWindowSize"
import { LAYOUT_BREAKPOINT_WIDTH } from "const"
import RelatedSymbols from '../components/RelatedSymbols'

function ModalShowNews({ isModalOpen, closeModal = () => { } }) {

  const { selectedNews } = useContext(NewsContext)
  const { width } = useWindowSize()
  const isMobile = width < LAYOUT_BREAKPOINT_WIDTH

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      style={{ content: { padding: 0, maxWidth: 648, margin: "auto", borderRadius: '12px', inset: isMobile ? '5px' : '40px' } }}
    >
      <div className="relative h-full overflow-hidden">
        <button className="sticky float-right right-2 top-2 text-xl text-primary z-50" title="Fechar" onClick={closeModal}>
          <MdClose />
        </button>
        {selectedNews?.link ? (
          <>
            <RelatedSymbols />
            <iframe src={selectedNews.link} className={`w-full px-4 mini-scrollbar ${selectedNews?.symbols ? 'h-[calc(97%-124px)]' : 'h-[97%]'}`} title={selectedNews.title} />
          </>
        ) : (
          <div className="text-grey-400 text-sm p-2">Unavailable</div>
        )}
      </div>
    </Modal>
  )
}

export default ModalShowNews



