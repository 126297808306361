import { ActionTypes as act } from "./types"
import { sortBy, pick, cloneDeep } from "lodash"

function reducer(state, { type, payload }) {

  switch (type) {

    // View Control
    case act.SELECT_LIST_BY_ID: {
      const mutation = {
        activeList: state.lists.find(x => x.id === payload) || state.lists[0]
      }
      return { 
        ...state,
        ...mutation,
        ...updateWidgetProps(state, mutation)
      }
    }

    case act.SET_VIEW: {
      if (payload === state.view)
        return state
      const mutation = { view: payload }
      return {
        ...state,
        ...mutation,
        ...updateWidgetProps(state, mutation)
      }
    }

    case act.SET_TABLE_TAB: {
      const mutation = { tableTab: payload }
      return {
        ...state,
        ...mutation,
        ...updateWidgetProps(state, mutation)
      }
    }

    case act.SET_LOADING: {
      return { ...state, loadingList: payload }
    }
    
    // Modal Control
    case act.OPEN_MODAL: {
      return { ...state, modal: payload }
    }

    case act.CLOSE_MODAL: {
      if (!state.modal)
        return state
      return { ...state, modal: payload }
    }

    // Data Control
    case act.CREATE_LIST: {
      const mutation = { 
        activeList: payload,
        lists: [...state.lists, payload],
      }
      return { 
        ...state,
        ...mutation,
        ...updateWidgetProps(state, pick(mutation, 'activeList'))
      }
    }

    case act.UPDATE_LIST: {
      const oldListIndex = state.lists.findIndex(({id}) => payload.id === id)
      if (oldListIndex < 0) 
        return state
      const lists = cloneDeep(state.lists)
      lists.splice(oldListIndex, 1, payload)

      const mutation = { lists, activeList: payload }

      return { 
        ...state,
        ...mutation,
      }
    }

    case act.DELETE_LIST: {
      const mutation = {
        lists: ordered(state.lists.filter(list => list.id !== payload)),
      }
      if (state.activeList.id === payload) {
        mutation.activeList = ordered(state.lists)[0]
      }
      return { 
        ...state,
        ...mutation,
        ...updateWidgetProps(state, pick(mutation, 'activeList')) 
      }
    }

    case act.UPDATE_FETCH_RESULTS: {
      const { data, loading, error } = payload

      const activeList = (() => {
        if (!data)
          return null
        if (state.activeList)
          return state.activeList
        const activeListId = state.widgetProps.params.activeListId
        const foundList = data.find(x => x.id === activeListId)
        if (foundList)
          return foundList
        return data.find(x => x.order === 1) || data[0]
      })()

      const mutation = {
        lists: ordered(data),
        loading,
        fetchError: error,
        activeList
      }

      return { 
        ...state,
        ...mutation,
      }
    }

    case act.UPDATE_EXTERNAL_DATA: {
      return { 
        ...state,
        externalData: payload
      }
    }

    case act.UPDATE_INTEREST_LIST: {

      const currentInterestListIndex = state.lists?.findIndex(list => payload.id === list.id)
      if (!payload.symbols || currentInterestListIndex < 0) {
        return state
      }
      
      const newLists = cloneDeep(state.lists)
      newLists[currentInterestListIndex].symbols = payload.symbols

      const mutation = { lists: newLists }
      if (state.activeList.id === payload.id) {
        mutation.activeList = newLists[currentInterestListIndex]
      }

      return {
        ...state,
        ...mutation
      }
    }

    default: {
      return state
    }
  }
}

function updateWidgetProps(state, mutation) {
  if (!state.widgetProps) {
    throw new Error('Missing "widgetProps" in state!')
  }
  if ('activeList' in mutation) {
    mutation.activeListId = mutation.activeList?.id || null
    delete mutation.activeList
  }
  const params = { 
    ...state.widgetProps.params,
    ...mutation
  }
  state.widgetProps.updateWidgetParams({ 
    id: state.widgetProps.id, 
    params
  })
  return {
    widgetProps: { 
      ...state.widgetProps,
      params
    },
  }
}

function ordered(allLists) {
  return sortBy(allLists, [x => x.order, x => x.name.trim().toLowerCase()])
}


export default reducer