import FundsApi from "api/FundsApi"
import PreferencesApi from "api/PreferencesApi"
import { FUNDS_TOOLTIPS } from 'const'
import moment from 'moment'
import { createContext, useContext, useEffect, useMemo, useState, useReducer } from "react"
import PreferencesContext from "./PreferencesContext"

export const FundsContext = createContext({
  stateFunds: {},
  dispatchFunds: () => {},
  fundActive: null,
  dateFormat: (type, beforeText) => { },
  listCategories: [],
  editListFunds: [],
  tabs: [],
  tabSelected: null,
  perCategory: null,
  tableStructure: [],
  searchPageStructure: [],
  selectedCategory: [],
  selectedFund: (id) => { },
  updatedListFunds: (cat) => { },
  formattedListPanel: (list) => { },
  createListPanel: (params) => { },
  updateListPanel: (params) => { },
  deleteListPanel: (params) => { },
  deleteListsGroup: (lists) => { },
  updateListCategoriesExisting: (category) => { },
  formattedPerCategory: (list) => {}
})

export function FundsContextProvider({ widgetProps, children, mobile, pageProps }) {
  const [fundActive, setFundActive] = useState(null)
  const [updateDates, setUpdateDates] = useState(null)
  const [listCategories, setListCategories] = useState(null)
  const { favoriteFundList, updateWalletList } = useContext(PreferencesContext)
  const [tabSelected, setTabSelected] = useState({ index: 0 })
  const [perCategory, setPerCategory] = useState(true)
  const [selectedCategory, setSelectedCategory] = useState([])
  const [listEditing, setListEditing] = useState(null)
  const [total, setTotal] = useState(400)
  const [pageConfig, setPageConfig] = useState(null)
  const initialState = {
    listPanel: [],
    listActive: null,
  }

  const reducerAction = (state, action) => {
    const states = {
      'SET_LIST_PANEL': { ...state, listPanel: action.payload },
      'UPDATE_LIST_PANEL': { ...state, listPanel: [...state.listPanel.filter((list) => action.payload?.id !== list.id), action.payload] },
      'DELETE_LIST_PANEL': { ...state, listPanel: state.listPanel.filter((list) => action.payload?.id !== list.id) },
      'SET_LIST_ACTIVE': { ...state, listActive: action.payload }
    }
    return states[action.type]
  }
  const [stateFunds, dispatchFunds] = useReducer(reducerAction, initialState)

  const widget = window.location.pathname.includes('workspace')
  const PAGE_CONFIG = `FundsPage`
  const WIDGET_CONFIG = `FundsWidget`

  const tabs = useMemo(() => [{ name: "LISTAS" }, { name: "BUSCA" }], [])
  const list = useMemo(() => [
    { Header: 'nome do fundo', type: 'name', accessor: 'foundName' },
    { Header: 'categoria', type: 'name', accessor: 'category', description: FUNDS_TOOLTIPS['Categoria'] },
    { Header: 'rent. anual med. 36 m', type: 'float', accessor: 'profitabilityThreeYears', description: FUNDS_TOOLTIPS['Rentabilidade em 36 meses'], updateAt: { date: 'monthlyUpdate', text: '*até' } },
    { Header: 'ranking valor - consistência', type: 'starGraphic', accessor: 'rankingValor', description: FUNDS_TOOLTIPS['Ranking Valor - Consistência'], updateAt: { date: 'lastQuarter', text: '*até' } },
    { Header: 'ranking valor - últimos 36 m', type: 'barGraphic', accessor: 'positionThreeYearsCategory', description: FUNDS_TOOLTIPS['Ranking Valor - Últimos 36 meses'], updateAt: { date: 'lastQuarter', text: '*até' } },
    { Header: 'rent. mês ant.', type: 'float', accessor: 'profitabilityOneMonth', description: FUNDS_TOOLTIPS['Rentabilidade no mês anterior'], updateAt: { date: 'monthlyUpdate', text: '*em' } },
    { Header: 'rent. no ano', type: 'float', accessor: 'profitabilityInYear', description: FUNDS_TOOLTIPS['Rent. no ano'], updateAt: { date: 'monthlyUpdate', text: '*até' } },
    { Header: 'rent. 12m', type: 'float', accessor: 'profitabilityOneYear', description: FUNDS_TOOLTIPS['Rentabilidade em 12 meses'], updateAt: { date: 'monthlyUpdate', text: '*até' } },
    { Header: 'rent. anual méd. 60 m', type: 'float', accessor: 'profitabilityFiveYear', description: FUNDS_TOOLTIPS['Rent. anual med. 60M'], updateAt: { date: 'monthlyUpdate', text: '*até' } },
    { Header: 'risco', type: 'percent', accessor: 'risk', description: FUNDS_TOOLTIPS['Risco'], updateAt: { date: 'lastQuarter', text: '*até' } },
    { Header: 'aplicação inicial min. (R$)', type: 'integer', accessor: 'minimumInitialApplication', description: FUNDS_TOOLTIPS['Aplicação inicial mínima'] },
    { Header: 'prazo de resgate', type: 'numberToString', accessor: 'rescueTime', description: FUNDS_TOOLTIPS['Prazo de resgate'] },
    { Header: 'taxa de adm. a.a.', type: 'percent', accessor: 'taxAdministration', description: FUNDS_TOOLTIPS['Taxa de adm a.a.'] },
    { Header: 'taxa de perfor.', type: 'integer', accessor: 'taxPerfm', description: FUNDS_TOOLTIPS['Taxa de performance'] },
  ], [perCategory])

  const searchPageStructure = useMemo(() => [
    { label: 'Categoria', content: fundActive?.category, type: 'string' },
    { label: 'Gestor', content: fundActive?.managerName, type: 'string' },
    { label: 'CNPJ', content: fundActive?.cnpjNumber, type: 'string' },
    { label: 'Cotistas', content: fundActive?.quotaHoldersCount, type: 'integer', date: `*${dateFormat('updateDaily')}` },
    { label: 'Patrimônio', content: fundActive?.patrimony, type: 'bigCurrency', date: `*${dateFormat('updateDaily')}` },
    { label: 'Aplicação inicial mínima', content: fundActive?.minimumInitialApplication, type: 'currency' },
    { label: 'Prazo de resgate', content: fundActive?.rescueTime, type: 'numberToString' },
    { label: 'Rentabilidade no mês anterior', content: fundActive?.profitabilityOneMonth, type: 'percent', date: `*em ${dateFormat('monthlyUpdate')}` },
    { label: 'Rentabilidade em 12 meses', content: fundActive?.profitabilityOneYear, type: 'percent', date: `*até ${dateFormat('monthlyUpdate')}` },
    { label: 'Rentabilidade em 36 meses', content: fundActive?.profitabilityThreeYears, type: 'percent', date: `*até ${dateFormat('monthlyUpdate')}` },
    { label: 'Taxa de adm. a.a. e performance', content: [fundActive?.taxAdministration, fundActive?.taxPerfm], type: 'specificStringFormat' },
    { label: 'Ranking Valor - Consistência', content: fundActive?.rankingValor, type: 'starGraphic', date: `*até ${dateFormat('lastQuarter')}` },
    { label: 'Ranking Valor - Últimos 36 meses', content: fundActive?.positionThreeYearsCategory, type: 'barGraphic', date: `*até ${dateFormat('lastQuarter')}` }
  ], [fundActive])

  const categoriesGroup = useMemo(() => [
    { name: 'Renda fixa', type: 'level', subCatId: [
      {id: 17, name: 'Renda Fixa DI'},
      {id: 16, name: 'Prefixado Renda Fixa Ativo'},
      {id: 11, name: 'Juro Real'},
      {id: 8, name: 'Crédito Privado Até 15 Dias'},
      {id: 7, name: 'Crédito Privado Acima de 16 Dias'},
      {id: 9, name: 'Debênture Incentivada'}]
    },
    { name: 'Renda variável', type: 'level', subCatId: [
      {id: 15, name: 'Multimercado Baixa Volatilidade'},
      {id: 14, name: 'Multimercado'},
      {id: 13, name: 'Long & Short'},
      {id: 12, name: 'Long Biased'},
      {id: 3, name: 'Ações Índice'},
      {id: 2, name: 'Ações'}]
    },
    { name: 'Alocação no exterior', type: 'level', subCatId: [
      {id: 4, name: 'Ações no Exterior'},
      {id: 10, name: 'Investimento no Exterior'}]
    },
    { name: 'Aplicam em outros fundos', type: 'level', subCatId: [
      {id: 6, name: 'Alocação Multimercado'},
      {id: 5, name: 'Alocação Ações'}]
    },
    { name: 'Outros', type: 'simple', subCatId: [
      {id: 1, name: 'Não Categorizado'}]
    }
  ], [])

  const categoryParent = (name) => {
    switch (name) {
      case "Renda Fixa DI":
      case "Prefixado Renda Fixa Ativo":
      case "Juro Real":
      case "Crédito Privado Até 15 Dias":
      case "Crédito Privado Acima de 16 Dias":
      case "Debênture Incentivada":
        return 'Renda fixa'
      case "Multimercado Baixa Volatilidade":
      case "Multimercado":
      case "Long & Short":
      case "Long Biased":
      case "Ações Índice":
      case "Ações":
        return 'Renda variável'
      case "Ações no Exterior":
      case "Investimento no Exterior":
        return 'Alocação no exterior'
      case "Alocação Multimercado":
      case "Alocação Ações":
        return 'Aplicam em outros fundos'
      case 'Não Categorizado':
        return 'Outros'
      default:
    }
  }

  const categoryOrder = {
    "Renda Fixa DI": 1,
    "Prefixado Renda Fixa Ativo": 2,
    "Juro Real": 3,
    "Crédito Privado Até 15 Dias": 4,
    "Crédito Privado Acima de 16 Dias": 5,
    "Debênture Incentivada": 6,
    "Multimercado Baixa Volatilidade": 1,
    "Multimercado": 2,
    "Long & Short": 3,
    "Long Biased": 4, 
    "Ações Índice": 5,
    "Ações": 6,
    "Ações no Exterior": 1,
    "Investimento no Exterior": 2,
    "Alocação Multimercado": 1,
    "Alocação Ações": 2,
    'Não Categorizado': 1
  }

  const [tableStructure, setTableStructure] = useState(list)

  function dateFormat(type, beforeText = null) {
    let text = ''
    if (beforeText) text += `${beforeText} `
    if (type === 'lastQuarter') text += `${moment(updateDates?.dateLastQuarter).format('MMM/YY').toLocaleLowerCase()}`
    if (type === 'monthlyUpdate') text += `${moment(updateDates?.dateMonthlyUpdate).format('MMM/YY').toLocaleLowerCase()}`
    if (type === 'updateDaily') text += `${moment(fundActive?.dateUpdateDaily).format('DD/MM/YY').toLocaleLowerCase()}`
    return text
  }

  async function updateParams(value) {
    if (widgetProps) {
      widgetProps.updateWidgetParams({ id: widgetProps.id, params: value })
      updatePreferencesConfiguration({key: WIDGET_CONFIG, data: value})
    }
    if (pageProps) updatePreferencesConfiguration({key: PAGE_CONFIG, data: value})
  }

  const paramsList = () => {
    if (widgetProps && widgetProps.params && Object.keys(widgetProps.params).length) return widgetProps.params
    else if (pageProps && pageConfig) {
      return pageConfig
    } else {
      return { id: 2, name: 'Ações' }
    }
  }

  function formattedPerCategory(list) {
    return list.sort((a, b) => (categoryOrder[a.category] < categoryOrder[b.category]) ? -1 : (categoryOrder[a.category] > categoryOrder[b.category] ? 1 : 0))
  }

  async function selectedFund({ cnpjNumberClean }) {
    if (cnpjNumberClean) {
      const { data, info } = await FundsApi.getFundId(cnpjNumberClean)
      setFundActive(data[0])
      setUpdateDates(info)
      if (data.length && widget) sessionStorage.setItem('FundSelected', JSON.stringify({ data, info }))
      return
    }
    setFundActive(null)
  }

  async function updatedListFunds(list, all) {
    if (list['name']) await formattedListCategory(list, all)
    if (list['panelName']) await formattedListPanel(list)
  }

  async function getCategories() {
    const categories = await FundsApi.getCategories()
    setListCategories(categories)
    return categories
  }

  async function formattedListCategory(list, all) {
    if (!list) return
    updateParams({ id: list.id, name: list.name, perCategory })
    const { data, info } = await FundsApi.getFilterId({ CategoriaIds: all ? all : [list.id], Top: all ? 2000 + total : total })
    setTotal((e) => e + 1)
    setUpdateDates(info)
    dispatchFunds({ type: 'SET_LIST_ACTIVE', payload: { ...list, funds: data.length? (perCategory ? formattedPerCategory(data) : data) : [] }})
  }

  async function getListsPanel() {
    const res = await FundsApi.getPanel()
    if (res.length) {
      const listOrder = res.sort((a,b) => {
        if (a.panelOrder === b.panelOrder) return a.panelName.localeCompare(b.panelName)
        else return a.panelOrder - b.panelOrder
      })
      dispatchFunds({ type: 'SET_LIST_PANEL', payload: listOrder })

    }
    return res
  }

  async function formattedListPanel(list) {
    if (!list || !list.id) return
    updateParams({ id: list.id, panelName: list.panelName, perCategory })
    if (list.panelName === 'FUNDOS FAVORITOS') {
      const favoriteList = JSON.parse(localStorage.getItem('MyFavoriteFundList'))
      await Promise.all(favoriteList.panelFundsRelResponses.map(async ({ fundCNPJClean }) => {
          const {data} = await FundsApi.getFundId(fundCNPJClean)
          return data[0]
        }))
        .then((data) => {
          const res = data.filter((row) => row)
          dispatchFunds({ type: 'SET_LIST_ACTIVE', payload: {...favoriteList, funds: res.length ? (perCategory ? formattedPerCategory(res) : res) : [] }})
        })
      return
    }
    const { data } = await FundsApi.getFilterId({ PanelId: list.id, Top: total })
    if (list.panelName === 'FUNDOS DA MINHA CARTEIRA') {
      const res  = await updateWalletList(list)
      const newList = { ...res, funds: data.length ? (perCategory ? formattedPerCategory(data) : data) : [] }
      dispatchFunds({ type: 'SET_LIST_ACTIVE', payload: newList })
      return
    }
    const newList = { ...list, funds: data.length ? (perCategory ? formattedPerCategory(data) : data) : [] }
    setTotal((e) => e + 1)
    dispatchFunds({ type: 'SET_LIST_ACTIVE', payload: newList })
  }
  
  async function createListPanel(params) {
    const res = await FundsApi.postPanel(params)
    dispatchFunds({ type: 'UPDATE_LIST_PANEL', payload: res })
    setSelectedCategory(res)
    formattedListPanel(res)
    return res
  }

  async function updateListPanel(params) {
    await FundsApi
      .putPanel(params)
      .then((list) => {
        updatedListFunds(list)
        dispatchFunds({ type: 'UPDATE_LIST_PANEL', payload: list })
        dispatchFunds({ type: 'SET_LIST_ACTIVE', payload: {...list, funds: [...stateFunds.listActive.funds]} })
      })
  }

  async function deleteListPanel(list) {
    await FundsApi.deletePanel(list)
    if (stateFunds.listActive.id === list.id) {
      setSelectedCategory(paramsList())
      formattedListCategory(paramsList())
    }
    dispatchFunds({ type: 'DELETE_LIST_PANEL', payload: list })
  }

  async function deleteListsGroup(lists) {
    await Promise.all(lists.map(async (list) => {
      await FundsApi.deletePanel(list)
    }))
    const listsUpdate = stateFunds.listPanel.filter((el) => !lists.some(({id}) => el.id === id)) 
    dispatchFunds({ type: 'SET_LIST_PANEL', payload: listsUpdate })
    if ('panelName' in stateFunds.listActive && !listsUpdate.some(({ id }) => id === stateFunds.listActive.id)) {
      setSelectedCategory({ id: 2, name: 'Ações' })
      formattedListCategory({ id: 2, name: 'Ações' })
    }
  }

  async function formattedListEditing(list) {
    if (!list) {
      setListEditing(null)
      return
    }
    const { data } = await FundsApi.getFilterId({ PanelId: list.id, Top: total })
    setListEditing({ ...list, funds: data.length ? data : [] })
  }

  async function getPreferencesConfiguration(key, lists) {
    if (pageProps) {
      await PreferencesApi
        .getConfiguration(key)
        .then(({ result }) => {
          let res = JSON.parse(result.data)
          if (!res) res = paramsList()
          const validationListLastActive = lists.find((list) => (list.id === res.id))
          if (validationListLastActive) res = validationListLastActive
          if (!validationListLastActive) res = paramsList()
          setPerCategory(res.perCategory ? res.perCategory : { perCategory: true } )
          setPageConfig(res)
          setSelectedCategory(res)
          updatedListFunds(res)
        })
      }
    if (widgetProps) {
      let params
      const listSelect = paramsList()
      const validationListLastActive = lists.find((list) => (list.id === listSelect.id))
      if (validationListLastActive) params = validationListLastActive
      if (!validationListLastActive) params = { id: 2, name: 'Ações' }
      setSelectedCategory(params)
      updatedListFunds(params)
    }
  }

  async function updatePreferencesConfiguration({key, data}) {
    return await PreferencesApi
      .updateConfiguration({key, data: JSON.stringify(data)})
  }

  useEffect(() => {
    if (tabSelected.index === 0) {
      Promise
        .all([getListsPanel(), getCategories()])
        .then(([lists, categories]) => {
          const data = lists.concat(categories)
          if (pageProps) getPreferencesConfiguration(PAGE_CONFIG, data)
          if (widgetProps) getPreferencesConfiguration(WIDGET_CONFIG, data)
        })
    }
  }, [])

  useEffect(() => {
    if (tabSelected) sessionStorage.setItem('FundsTabSelected', JSON.stringify(tabSelected))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabSelected])

  useEffect(() => {
    moment.updateLocale('en', {
      monthsShort: [
        "Jan", "Fev", "Mar", "Abr", "Mai", "Jun",
        "Jul", "Ago", "Set", "Out", "Nov", "Dez"
      ]
    })
    if (widget) {
      const fundSession = sessionStorage.getItem('FundSelected')
      if (fundSession) {
        const { data, info } = JSON.parse(fundSession)
        setFundActive(data[0])
        setUpdateDates(info)
      }
      const tabSession = sessionStorage.getItem('FundsTabSelected')
      if (tabSession) {
        const parseTabSession = JSON.parse(tabSession)
        setTabSelected({ index: parseTabSession.index })
      } else setTabSelected({ index: 0 })
    }
  }, [widget])

  useEffect(() => {
    selectedCategory.id
      ? updatedListFunds(selectedCategory)
      : updatedListFunds(selectedCategory, selectedCategory.subCatId?.map(({ id }) => id))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [perCategory])

  useEffect(() => {
    let tableCurrent = list
    if (mobile && perCategory) tableCurrent = list.filter(({ accessor }) => accessor !== 'foundName' && accessor !== 'category')
    else if (mobile) tableCurrent = list.filter(({ accessor }) => accessor !== 'foundName')
    else if (perCategory) tableCurrent = list.filter(({ accessor }) => accessor !== 'category')
    else if (selectedCategory.id && !perCategory && !('panelName' in selectedCategory)) tableCurrent = list.filter(({ accessor }) => accessor !== 'category')
    setTableStructure(tableCurrent)
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [mobile, perCategory, selectedCategory])

  useEffect(() => {
    if (stateFunds.listActive?.id === favoriteFundList?.id) formattedListPanel(favoriteFundList)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favoriteFundList])

  useEffect(() => {
    if (stateFunds.listPanel.length) getListsPanel()
  }, [stateFunds.listActive])

  return (
    <FundsContext.Provider value={{
      stateFunds,
      dispatchFunds,
      mobile,
      fundActive,
      dateFormat,
      listCategories,
      selectedFund,
      tabs,
      tabSelected,
      setTabSelected,
      categoriesGroup,
      perCategory,
      setPerCategory,
      searchPageStructure,
      tableStructure,
      selectedCategory,
      setSelectedCategory,
      updatedListFunds,
      formattedListPanel,
      formattedListCategory,
      createListPanel,
      updateListPanel,
      deleteListPanel,
      deleteListsGroup,
      listEditing,
      formattedListEditing,
      categoryParent,
      favoriteFundList,
      formattedPerCategory
    }}>
      {children}
    </FundsContext.Provider>
  )
}
