import Modal from "react-modal"
import CloseButton from "shared/CloseButton"
import useWindowSize from "hooks/useWindowSize"
import { LAYOUT_BREAKPOINT_WIDTH } from "const"

function CompaniesList({ isOpen = true, closeModal, editItem }) {

  const { width } = useWindowSize()
  const isMobile = width < LAYOUT_BREAKPOINT_WIDTH

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} style={{
      content:
      {
        maxWidth: isMobile ? width : 648,
        margin: "auto", 
        marginRight: isMobile ? -40 : "auto",
        marginLeft: isMobile ? -40 : "auto",
      }
    }} shouldCloseOnEsc={true}>
      <CloseButton onClick={closeModal} />
      <h2 className="font-bold text-xl text-primary uppercase mb-2">Empresas</h2>

      <div className="relative mini-scrollbar mt-5">
        <table className="w-full text-sm text-left">
          <thead className=" text-primary ">
            <tr className="border-b dark:border-gray-400">
              <th className="font-bold text-lg uppercase px-6 py-3">
                ATIVO
              </th>
              <th className="font-bold text-lg uppercase px-6 py-3">
                EMPRESA
              </th>
            </tr>
            {editItem.map((item) => (
              <tr key={item.ativo}>
                <th
                  className="px-6 py-2 font-medium text-base text-gray-900"
                >
                  {item.ativo}
                </th>
                <th
                  className="px-6 py-2 font-medium text-base text-gray-900"
                >
                  {item.nomeFantasia}
                </th>
              </tr>
            ))}
          </thead>
        </table>
      </div>
    </Modal>
  )
}

export default CompaniesList