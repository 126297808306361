import { convertToInternationalCurrencySystem } from "helpers/numberHelper"
import IcoMarketMap from "assets/icons/market-map/ico-market-map.svg"
import { WidgetTitle, WidgetLinkTitle } from "shared/WidgetTitle"
import WidgetContextualMenu from "shared/WidgetContextualMenu"
import { useState, useEffect, useMemo } from "react"
import { AgChartsReact } from 'ag-charts-react'
import { LoadingBox } from "shared/Loading"
import StatisticApi from "api/StatisticApi"
import { IbovInfo } from "shared/IbovInfo"
import { useTimer } from "hooks/useTimer"
import { uniqBy } from "lodash"
import useTitle from "hooks/useTitle"
import DeniedPermissionWidget from "shared/DeniedPermissionWidget"
import { LAYOUT_BREAKPOINT_WIDTH_MOBILE } from "const"
import useMaxWindowSize from "hooks/useMaxWindowSize"

export function MarketMapWidget({ ...props }) {
  
  const isMobile = useMaxWindowSize(LAYOUT_BREAKPOINT_WIDTH_MOBILE)
  const widgetSize = props[isMobile ? 'mobile' : 'desktop'].w

  return (
    <div className="bg-white h-full rounded flex flex-col">
      <div className="drag-handle pt-4 px-4">
        <WidgetContextualMenu removeItem={() => props.removeItem(props.id)} />
        <WidgetLinkTitle route="/mapa-de-mercado" title="Mapa de Mercado" />
      </div>
      <DeniedPermissionWidget widgetSize={widgetSize} widgetType="MarketMap">
        <div className="px-4">
          <IbovInfo />
        </div>
        <MarketMapStructure />
      </DeniedPermissionWidget>
    </div>
  )
}

export function MarketMapPage() {
  useTitle('Mapa de Mercado')

  return (
    <div className="bg-white h-full rounded flex flex-col" style={{ minHeight: 640 }} >
      <div className="pt-4 px-4">
        <WidgetTitle title="Mapa de Mercado" />
        <IbovInfo />
      </div>
      <MarketMapStructure />
    </div>
  )
}

function MarketMapStructure() {
  const [marketMap, setMarketMap] = useState(null)
  const [lastUpdate, setLastUpdate] = useState(null)
  const timer = useTimer(60000)

  useEffect(() => {
    StatisticApi.fetchIBOVQuotationAsset({ timer }).then((marketMapList) => {
      const handledData = handleData(marketMapList)
      setMarketMap(handledData)
      setLastUpdate(new Date())
    })
  }, [timer])

  return (
    <>
      <MarketMapList data={marketMap} />
      <p className="dark-gray text-xs text-center mb-4">
        Atualizado às {lastUpdate?.toLocaleString('PT-br').slice(-8)}
      </p>
    </>
  )
}

function MarketMapList({ data }) {
  const legends = useMemo(() => [
    { value: -4, label: '< -4%' },
    { value: -3, label: '-3%' },
    { value: -2, label: '-2%' },
    { value: -1, label: '-1%' },
    { value: 0, label: '0%' },
    { value: 1, label: '+1%' },
    { value: 2, label: '+2%' },
    { value: 3, label: '+3%' },
    { value: 4, label: '> +4%' },
  ], [])

  if (!data) return <LoadingBox />
  data = data.children.every(x => !x.var) ? null : data

  return (
    <div className="space-y-3 flex flex-col h-full w-full items-center justify-between py-4 px-3">
      {data ? (
        <div style={{ height: 'calc(100% - 30px)' }} className="w-full touch-none">
          <AgChartsReact options={getTreeMapOptions(data)} />
        </div>
      ) : (
        <div style={{ height: 'calc(100% - 30px)' }} className="flex items-center justify-center flex-col w-full">
          {/* <MdDashboard className="text-gray-400 text-7xl mb-5" /> */}
          <img src={IcoMarketMap} className="text-gray-400 text-7xl mb-5" alt="Mapa de mercado" />
          <p className="text-gray-400">Aguardando abertura do mercado...</p>
        </div>
      )}
      <div className="text-center text-xs font-semibold flex rounded overflow-hidden w-full max-w-[466px]">
        {legends.map(({ value, label }) => (
          <div className="flex-grow" key={"percent-" + value} style={{ background: getColorVal(value) }}>
            {label}
          </div>
        ))}
      </div>
    </div>
  )
}

//Ajustar Cores
function getColorVal(val) {
  if (val < -3) return "#F75543"
  if (val < -2 && val >= -3) return "#F47C6E"
  if (val < -1 && val >= -2) return "#F2A198"
  if (val < -0 && val >= -1) return "#F0C7C3"
  if (val === 0) return "#EDEDED"
  if (val > 0 && val <= 1) return "#D8EABE"
  if (val > 1 && val <= 2) return "#c3e58f"
  if (val > 2 && val <= 3) return "#afe261"
  if (val > 3) return "#9ade31"
}

function handleData(marketMapList) {
  if (!marketMapList) return null

  marketMapList = uniqBy(
    marketMapList.filter((x) => x.ult !== 0),
    "symbol"
  )

  const categories = []
  for (const item of marketMapList) {
    item.name = item.symbol
    let category = categories.find(cat => cat.name === item.valorSector)
    if (!category) {
      category = { name: item.valorSector, children: [] }
      categories.push(category)
    }

    let subcategory = category.children.find(sub => sub.name === item.bovespSector)
    if (!subcategory) {
      subcategory = { name: item.bovespSector, children: [] }
      category.children.push(subcategory)
    }
    subcategory.children.push(item)
  }

  for (const category of categories) {
    for (const subcategory of category.children)
      subcategory.var = subcategory.children.reduce((ac, x) => ac + x.var, 0) / subcategory.children.length
    category.var = category.children.reduce((ac, x) => ac + x.var, 0) / category.children.length
  }

  return { name: 'IBOV', children: categories }
}

function getTreeMapOptions(data) {
  return {
    data,
    padding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
    series: [
      {
        type: 'treemap',
        labelKey: 'name',
        sizeKey: 'volf',
        colorKey: 'var',
        gradient: false,
        groupFill: 'white',
        colorDomain: [-4, -3, -2, -1, 0, 1, 2, 3, 4],
        colorRange: [
          "#F75543",
          "#F47C6E",
          "#F2A198",
          "#F0C7C3",
          "#EDEDED",
          "#D8EABE",
          "#c3e58f",
          "#afe261",
          "#9ade31"
        ],
        tileStroke: '#EDEDED',
        tileStrokeWidth: 1,
        groupStroke: '#EDEDED',
        groupStrokeWidth: 0.5,
        nodePadding: 2,
        // nodeGap: 1,
        // highlightGroups: false,
        highlightStyle: {
          text: {
            color: 'black',
            fontFamily: 'Red Hat Text',
          },
          item: {
            fill: 'transparent',
            stroke: '#282828'
          }
        },
        labelShadow: {
          enabled: false,
        },
        title: {
          color: 'black',
          fontFamily: 'Red Hat Text'
        },
        subtitle: {
          color: 'black',
          fontFamily: 'Red Hat Text'
        },
        tooltip: {
          renderer: (params) => {
            return {
              title: `<div style="font-family: 'Red Hat Text'">${params.title}</div>`,
              content: params.datum.ult ? `
                <div style="font-family: 'Red Hat Text'">
                  ${params.datum.valorSector ? `<b>Setor Econômico</b>: ${params.datum.valorSector}<br />` : ''}
                  ${params.datum.bovespSector ? `<b>Subsetor</b>: ${params.datum.bovespSector}<br />` : ''}
                  ${params.datum.companyName ? `<b>Empresa</b>: ${params.datum.companyName}<br />` : ''}
                  ${typeof params.datum.ult === 'number' ? `<b>Preço</b>: R$ ${params.datum.ult?.toFixed(2).replace('.', ',')}<br />` : ''}
                  ${typeof params.datum[params.colorKey] === 'number' ? `<b>Variação</b>: ${params.datum[params.colorKey]?.toFixed(2).replace('.', ',')}%<br />` : ''}
                  ${typeof params.datum.volf === 'number' ? `<b>Volume financeiro</b>: R$ ${convertToInternationalCurrencySystem(params.datum.volf)}<br />` : ''}
                </div>
              ` : '',
              color: 'black'
            }
          },
        },
        labels: {
          value: {
            formatter: (params) => `${params.datum.var?.toFixed(2)?.replace('.', ',')}%`,
            style: {
              color: '#222',
              fontWeight: '600',
              fontFamily: 'Red Hat Text'
            }
          },
          small: { color: '#222', fontWeight: '600', fontFamily: 'Red Hat Text' },
          medium: { color: '#222', fontWeight: '600', fontFamily: 'Red Hat Text' },
          large: { color: '#222', fontWeight: '600', fontFamily: 'Red Hat Text' }
        },
      },
    ],
    overlays: {
      noData: {
        text: "Nenhum dado a ser exibido. Verifique se o mercado está aberto."
      }
    }
  }
}
