import { abs } from "helpers/numberHelper"
import { useEffect, useRef, useState } from "react"
import { Keys } from "const"
import useSymbol from "hooks/useSymbol"
import Blink from "shared/Blink"
import { SimpleTooltip } from "shared/HelpTooltip"
import iconQuotationMax from 'assets/icons/panel/preco-maximo.svg'
import iconQuotationMin from 'assets/icons/panel/preco-minimo.svg'

function IconToggle({ data }) {

  const [iconSelected, setIconSelected] = useState(null)

  useEffect(() => {
    if (data && !data[Keys.ULTCOT]) return setIconSelected(null)
    setIconSelected(
      data && data[Keys.ULTCOT] === data[Keys.MAX] ?
        { icon: iconQuotationMax, msg: 'Igual ao preço máximo' } :
        data && data[Keys.ULTCOT] === data[Keys.MIN] ?
          { icon: iconQuotationMin, msg: 'Igual ao preço mínimo' } :
          null
    )
  }, [data])

  if (!data) return

  return iconSelected &&
    <>
      <span
        id={`iconMaxMin-${data.symbol}`}
        className={`flex items-center cursor-pointer`}
      >
        <img src={iconSelected.icon} alt={iconSelected.icon} />
      </span>
      <SimpleTooltip
        anchor={`#iconMaxMin-${data.symbol}`}
        value={iconSelected.msg}
      />
    </>
}

function Price({ symbol, origin, containerClass = '', varClass = '', quoteDate = false, props, clock }) {

  const nodeRef = useRef(null)
  const data = useSymbol(symbol, origin, null, props)
  const color = data && (data[Keys.VAR] === 0 || !data[Keys.VAR] ? 'text-gray-500' : data[Keys.VAR] > 0 ? 'text-profit' : 'text-loss')
  const [fractionDigits, setFractionDigits] = useState()
  const [dateLastCotation, setDateLastCotation] = useState('')

  useEffect(() => {
    setFractionDigits(Math.min(((data?.cdec || 2)), 4))
    if (data && data[Keys.DULTCOT]) {
      const options = {
        month: '2-digit',
        day: '2-digit'
      }
      const lastDate = Date.parse(data[Keys.DULTCOT])
      setDateLastCotation(new Intl.DateTimeFormat('pt-BR', options).format(lastDate))
    }
  }, [data])

  let className = 'bg-opacity-5 rounded py-1'
  if (data)
    className += (data[Keys.VAR] === 0 || !data[Keys.VAR] ? 'text-gray-500 bg-gray-500' : (data[Keys.VAR] > 0 ? 'text-profit bg-profit' : 'text-loss bg-loss'))

  return (
    <div className={containerClass}>
      <div className="flex gap-1">
        <IconToggle data={data} />
        {data && <h5 ref={nodeRef} className="font-semibold blink">
          {
            data[Keys.ULTCOT] ?
              data.unid ? <Blink className={className}>
                <span>{data[Keys.ULTCOT]?.toLocaleString('pt-BR', { minimumFractionDigits: fractionDigits, maximumFractionDigits: fractionDigits })}</span>
                <span className="ml-1 text-sm text-gray-500">{data.unid ? data.unid : ''}</span>
              </Blink> :
                <Blink className={className} value={data[Keys.ULTCOT]?.toLocaleString('pt-BR', { minimumFractionDigits: fractionDigits, maximumFractionDigits: fractionDigits })} /> :
              <div className="font-normal text-sm">-</div>
          }
        </h5>}
      </div>
      {data && <h6 className={`space-x-1 font-bold ${color} ${varClass}`}>
        {
          data[Keys.ULTCOT] && !data[Keys.VAR] && data[Keys.VAR] !== 0 ? <div className="text-xs font-semibold text-gray-500">{quoteDate ? dateLastCotation : '-'}</div> :
            data[Keys.VAR] === 0
              ? <div>{`${Number(0).toFixed(fractionDigits).replace('.', ',')} (0,00)`}</div>
              : <>
                {data[Keys.DIFFEC] && <Blink className={className} value={data[Keys.DIFFEC]?.toLocaleString('pt-BR', { minimumFractionDigits: fractionDigits, maximumFractionDigits: fractionDigits })} />}
                {data[Keys.VAR] && <Blink className={className} value={`(${abs(data[Keys.VAR], 'currency')}%)`} />}
              </>
        }
      </h6>}
      {clock}
    </div>
  )
}


export default Price