import { BASE_URL, getApiHeaders, customFetch } from "api/settings"
import { ORIGINS } from "const"
import { FetchError, InternalFetchError } from "errors/FetchError"
import emptyLayout from "settings/emptyLayout"

const URL = 'Preferences'

const Api = {

  getInterestList: async function () {
    try {
      const res = await customFetch(`${BASE_URL}/${URL}/interestlist`, { method: 'GET', headers: getApiHeaders() })
      const resBody = await res.json()
      if (!res.ok) throw new FetchError(res, resBody)
      return resBody
    } catch (error) {
      if (error instanceof FetchError) throw error
      throw new InternalFetchError(error)
    }
  },

  getPanelQuotationList: async function () {
    try {
      const res = await customFetch(`${BASE_URL}/${URL}/panelquotation`, { method: 'GET', headers: getApiHeaders() })
      const resBody = await res.json()
      if (!res.ok) throw new FetchError(res, resBody)
      return resBody
    } catch (error) {
      if (error instanceof FetchError) throw error
      throw new InternalFetchError(error)
    }
  },

  getPanelFundsListWallet: async function () {
    try {
      const res = await customFetch(`${BASE_URL}/${URL}/panelquotationativosfundos`, { method: 'GET', headers: getApiHeaders() })
      const resBody = await res.json()
      if (!res.ok) throw new FetchError(res, resBody)
      return resBody
    } catch (error) {
      if (error instanceof FetchError) throw error
      throw new InternalFetchError(error)
    }
  },

  createPanelQuotationList: async function (body) {
    try {
      const res = await customFetch(`${BASE_URL}/${URL}/panelquotation`, {
        method: 'POST',
        headers: getApiHeaders(),
        body: JSON.stringify(body)
      })
      const resBody = await res.json()
      if (!res.ok) throw new FetchError(res, resBody)

      return resBody
    } catch (error) {
      if (error instanceof FetchError) throw error
      throw new InternalFetchError(error)
    }
  },

  updatePanelQuotationList: async function (body) {
    try {
      const res = await customFetch(`${BASE_URL}/${URL}/panelquotation`, {
        method: 'PUT',
        headers: getApiHeaders(),
        body: JSON.stringify(body)
      })
      const resBody = await res.json()
      if (!res.ok) throw new FetchError(res, resBody)
      return resBody
    } catch (error) {
      if (error instanceof FetchError) throw error
      throw new InternalFetchError(error)
    }
  },

  deletePanelQuotationList: async function (id) {
    try {
      const res = await customFetch(`${BASE_URL}/${URL}/panelquotation`, {
        method: 'DELETE',
        headers: getApiHeaders(),
        body: JSON.stringify([id])
      })
      const resBody = await res.json()
      if (!res.ok) throw new FetchError(res, resBody)
      return resBody
    } catch (error) {
      if (error instanceof FetchError) throw error
      throw new InternalFetchError(error)
    }
  },

  addRemovePanelQuotationInterest: async function (body) {
    try {
      const res = await customFetch(`${BASE_URL}/${URL}/panelquotationinterest`, {
        method: 'PUT',
        headers: getApiHeaders(),
        body: JSON.stringify(body)
      })
      const resBody = await res.json()
      if (!res.ok) throw new FetchError(res, resBody)
      return resBody
    } catch (error) {
      if (error instanceof FetchError) throw error
      throw new InternalFetchError(error)
    }
  },

  getWorkspaces: async function () {
    try {
      const res = await customFetch(`${BASE_URL}/${URL}/workspace`, { method: 'GET', headers: getApiHeaders() })
      const resBody = await res.json()
      if (!res.ok) throw new FetchError(res, resBody)

      const workspaces = resBody
      
      if (!workspaces)
        throw new Error('Missing workspace!')

      return workspaces.map(item => {
        let data = JSON.parse(item.data)
        // data = null // Debug workspace empty layout
        if (!data || !Array.isArray(data)) {
          data = emptyLayout
        }
        data = data.filter(item => (
          typeof item.desktop?.x === 'number' &&
          typeof item.desktop?.y === 'number' &&
          typeof item.mobile?.x === 'number' &&
          typeof item.mobile?.y === 'number'
        ))
        return { ...item, data }
      })

    } catch (error) {
      if (error instanceof FetchError) throw error
      throw new InternalFetchError(error)
    }
  },

  updateWorkspace: async function (workspace) {
    try {
      workspace.data = JSON.stringify(workspace.data || [])
      const res = await customFetch(`${BASE_URL}/${URL}/workspace`, {
        method: 'PUT',
        headers: getApiHeaders(),
        body: JSON.stringify(workspace)
      })
      const resBody = await res.json()
      if (!res.ok) throw new FetchError(res, resBody)
      return resBody
    } catch (error) {
      if (error instanceof FetchError) throw error
      throw new InternalFetchError(error)
    }
  },

  createUserIntegrationDocument: async function (document) {
    try {
      document = document.replace(/[\D]/g, '')
      const res = await customFetch(`${BASE_URL}/${URL}/userintegration/${document}`, { method: 'POST', headers: getApiHeaders() })
      const resBody = await res.json()
      if (!res.ok) throw new FetchError(res, resBody)
      return resBody
    } catch (error) {
      if (error instanceof FetchError) throw error
      throw new InternalFetchError(error)
    }
  },

  updateUserIntegrationStatus: async function (status) {
    try {
      const res = await customFetch(`${BASE_URL}/${URL}/userintegration/${status}`, { method: 'PUT', headers: getApiHeaders() })
      const resBody = await res.json()
      if (!res.ok) throw new FetchError(res, resBody)
      return resBody
    } catch (error) {
      if (error instanceof FetchError) throw error
      throw new InternalFetchError(error)
    }
  },
  
  updateNewsFilters: async function (payload) {
    const handledPayload = {
      origin: Object.entries(payload.origin || []).filter(([k, v]) => v).map(([k]) => k),
      categories: payload.categories || [],
      companies: (payload.companies || []).map(x => JSON.stringify(x)),
      keyword: payload.keyword || [],
      isWalletSyncActive: payload.isWalletSyncActive || false,
    }

    try {
      const res = await customFetch(`${BASE_URL}/${URL}/news`, { 
        method: 'POST',
        headers: getApiHeaders(),
        body: JSON.stringify(handledPayload)
      }) 
      const resBody = await res.json()
      if (!res.ok) throw new FetchError(res, resBody)
      resBody.origin = ORIGINS.reduce((ac, {key}) => ({ ...ac, [key]: resBody.origin.includes(key) }), {})
      resBody.companies = resBody.companies.map(x => JSON.parse(x))
      return resBody
    } catch (error) {
      if (error instanceof FetchError) throw error
      throw new InternalFetchError(error)
    }
  },

  getNewsFilters: async function () {
    try {
      const res = await customFetch(`${BASE_URL}/${URL}/news`, { headers: getApiHeaders() })
      if (res.status === 204) return null
      const resBody = await res.json()
      if (!res.ok) throw new FetchError(res, resBody)
      resBody.origin = ORIGINS.reduce((ac, {key}) => ({ ...ac, [key]: resBody.origin.includes(key) }), {})
      resBody.companies = resBody.companies.map(x => JSON.parse(x))
      return resBody
    } catch (error) {
      if (error instanceof FetchError) throw error
      throw new InternalFetchError(error)
    }
  },

  getConfiguration: async function (key) {
    try {
      const res = await customFetch(`${BASE_URL}/${URL}/configuration?key=${key}`, { headers: getApiHeaders() })
      if (res.status === 204) return null
      const resBody = await res.json()
      if (!res.ok) throw new FetchError(res, resBody)
      return resBody
    } catch (error) {
      if (error instanceof FetchError) throw error
      throw new InternalFetchError(error)
    }
  },

  updateConfiguration: async function (body) {
    try {
      const res = await customFetch(`${BASE_URL}/${URL}/configuration`, { 
        method: 'POST',
        headers: getApiHeaders(),
        body: JSON.stringify(body)
      }) 
      const resBody = await res.json()
      if (!res.ok) throw new FetchError(res, resBody)
      return resBody
    } catch (error) {
      if (error instanceof FetchError) throw error
      throw new InternalFetchError(error)
    }
  }


}

export default Api