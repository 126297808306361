import { useMemo, useContext, Fragment, useState, useEffect } from 'react'
import Modal from "react-modal"
import { MdOutlineModeEditOutline, MdArrowBackIos, MdOutlineManageSearch, MdOutlineNotifications } from "react-icons/md"
import { toast } from 'react-toastify'
import lodash from 'lodash'
import { AlertMonitorProvider, AlertMonitorContext } from 'context/AlertMonitorContext'
import useWindowSize from 'hooks/useWindowSize'
import useMobile from 'hooks/useMobile'
import Tabs from 'shared/Tabs'
import { WidgetTitle } from "shared/WidgetTitle"
import { ToggleCustom } from 'shared/ToggleButton'
import { CheckboxBasic } from 'shared/Checkbox'
import NotificationModal from 'shared/modal/NotificationModal'
import ModalNotificationPermission from 'pages/Alerts/modals/ModalRequestNotificationPermission'
import CloseButton from "shared/CloseButton"
import { LoadingBox } from "shared/Loading"
import { SimpleTooltip } from 'shared/HelpTooltip'
import useTitle from 'hooks/useTitle'


function useAlertMonitor() {
  const [isOpenNotification, setIsOpenNotification] = useState(false)
  const [activeListChosen, setActiveListChosen] = useState(null)

  return {
    isOpenNotification,
    setIsOpenNotification,
    activeListChosen,
    setActiveListChosen
  }
}

function AlertMonitor() {

  const { height, width } = useWindowSize()
  const { isMobile } = useMobile()
  const [mobileView, setMobileView] = useState(0)
  const [permission, setPermission] = useState(Notification.permission)
  useTitle('Monitoração de Investimentos')
  
  const menuActive = (e) => {
    setMobileView(e)
  }

  return (
    <AlertMonitorProvider>
      <div style={{minHeight: isMobile ? mobileView === 0 ? '100%' : '' : `${height - 140}px`, height: isMobile && mobileView === 0 ? '-webkit-fill-available' : '' }} className={`flex flex-col ${isMobile ? '' : 'm-[30px]' } p-[30px] bg-white rounded-xl`}>
        <WidgetTitle title='Monitoração de Investimentos' />
        <NavigatorNotification permission={permission} setPermission={setPermission} />
        {isMobile 
          ? { 
              0 : <Menu mobile={isMobile} onChange={menuActive} />,
              1 : <Content mobile={isMobile} size={{width, height}} onChangeViewMobile={menuActive} />
            }[mobileView]
            
          : <div className="h-full flex rounded-xl p-2">
              <Menu size={{width}} />
              <Content size={{width, height}} />
            </div>
        }
      </div>
    </AlertMonitorProvider>
  )
}

function NavigatorNotification({ permission, setPermission = () => {} }) {

  const [isOpenModalPermission, setIsOpenModalPermission] = useState(false)

  const requestPermission = async () => {
    try {
      const result = await Notification.requestPermission()
      setPermission(result)
      if (result === 'denied') setIsOpenModalPermission(true)
    } catch (error) {
      console.error('Erro ao solicitar permissão de notificação:', error)
    }
  };

  return (
    <>
      {permission !== 'granted' &&
        <div className="m-2 mb-4 border-[1px] border-[#C5C5C5] bg-[#EDEDED] p-[30px] flex rounded-[10px] w-auto items-start align-middle">
          <div className="py-[1px]">
            <MdOutlineNotifications className="text-warning text-2xl text-primary" />
          </div>
          <p className="ml-[10px]">
            Ative as notificações para receber os alertas do Valor One neste dispositivo. <p className="link underline font-[600] cursor-pointer" target="_blank" rel="noreferrer" onClick={() => { requestPermission() }}>Clique para ativar</p>.
          </p>
        </div>}
      {isOpenModalPermission && <ModalNotificationPermission closeModal={() => setIsOpenModalPermission(false)} />}
    </>
  )
}

function Menu({width, mobile, onChange = () => {}}) {
  
  const {
    listsPanel,
    activeList,
    updateListsPanel,
    selectedActiveList,
    saveLists,
    cancelUpdateList,
    editConfigs,
    setEditConfigs
  } = useContext(AlertMonitorContext)
  const tabs = useMemo(() => [{ name: "PAINEL" }], [])
  const use = useAlertMonitor()
  
  const onChangeToggle = (e, list) => {
    updateListsPanel({ params: { flgEnabled: e }, list })
    saveLists({ ...list, flgEnabled: e })
  }

  const expectedActiveList = (list) => {
    if (mobile || !editConfigs) selectedActiveList(list)
    else {
      use.setActiveListChosen(list)
      use.setIsOpenNotification(true)
    }
    onChange(1)
    setEditConfigs(false)
  }

  const updateActiveList = () => {
    saveLists(listsPanel.find(({ id }) => id === activeList))
    if (!mobile) {
      selectedActiveList(use.activeListChosen)
      use.setIsOpenNotification(false)
    }
    toast.success(`As alterações foram salvas com sucesso!`)
  }

  const closeModal = () => {
    selectedActiveList(use.activeListChosen)
    use.setIsOpenNotification(false)
    cancelUpdateList()
  }

  return (
    <div className={`bg-light-gray ${mobile ? 'w-full h-full rounded-xl' : 'w-[368px] rounded-s-xl border-r-2 border-[#DBDBDB]'} `}>
      <div className='pl-[30px]'>
        <Tabs
          tabs={tabs}
          activeTabIndex={{index: 0}}
          parentClass={`flex space-x-[50px] mt-[22px] mb-[10px]`}
          tabClass={'pb-2 text-base'}
          tabSelectedClass={'border-b-4'}
        />
      </div>
      <div>
        {listsPanel.length > 0 && listsPanel.map((list) => {
          return (
            <Fragment key={`List_Menu_Alert_${list.id}`}>
              <div
                onClick={() => !mobile && list.id !== activeList.id && expectedActiveList(list)}
                className={`${activeList.name === list.name && !mobile ? 'bg-white border-y-2 border-l-2 border-r-0 border-[#DBDBDB] rounded-s-xl' : ''} flex justify-between items-center cursor-pointer px-[30px] py-3 -mr-[2px]`}
              >
                { activeList && <>
                  <span onClick={() => mobile && expectedActiveList(list)} className='text-base text-primary font-semibold'>{list.name}</span>
                  <div className='flex space-x-3
                  '>
                    <div className='w-[1px] bg-[#C5C5C5] my-1'></div>
                    <ToggleCustom id={list.id} isChecked={list.flgEnabled} onChange={(e) => onChangeToggle(e, list)} disabled={!mobile && list.id !== activeList.id} />
                  </div>
                </>}
              </div>
              <NotificationModal
                isOpen={use.isOpenNotification}
                closeModal={() => closeModal()}
                title={`Deseja salvar as modificações?`}
                text='Você alterou as configurações desta lista, e perderá as alterações se não forem salvas.'
                style={{ content:
                  {
                    maxWidth: 430,
                    maxHeight: width < 500 ? 360 : 270,
                    borderRadius: 10,
                    margin: "auto",
                    marginRight: mobile ? -40 : "auto",
                    marginLeft: mobile ? -40 : "auto"
                  }
                }}
                successLabel="Salvar"
                closeLabel="Descartar"  
                successModal={() => updateActiveList()}
                actionButtons 
              />
            </Fragment>
          )
        })}
      </div>
    </div>
  )
}

function Content({ width, height, mobile, onChangeViewMobile = () => {} }) {
  const { 
    listsPanel,
    activeList,
    updateListsPanel,
    selectedActiveList,
    saveLists,
    cancelUpdateList,
    editConfigs,
    setEditConfigs,
    restoreDefault
  } = useContext(AlertMonitorContext) 
  const [isOpenModalActivesInPanel, setIsOpenModalActivesInPanel] = useState(false)
  const [updateSymbols, setUpdateSymbols] = useState([])
  const [updateRenderItems, setUpdateRenderItems] = useState(false)

  const use = useAlertMonitor()

  const updateActiveList = () => {
    saveLists(listsPanel.find(({ id }) => id === activeList))
    setEditConfigs(false)
    if (!mobile) {
      selectedActiveList(use.activeListChosen)
      use.setIsOpenNotification(false)
    }
    onChangeViewMobile(0)
    toast.success(`As alterações foram salvas com sucesso!`)
    setUpdateRenderItems((e) => !e)
  }

  const cancelUpdate = () => {
    cancelUpdateList()
    setUpdateRenderItems((e) => !e)
  }

  const closeModal = () => {
    selectedActiveList(use.activeListChosen)
    use.setIsOpenNotification(false)
    cancelUpdate()
    onChangeViewMobile(0)
  }

  function configsUpdate(data) {
    let configUpdate
    const configs = listsPanel
      .find(({ id }) => id === activeList.id)
      .panelInvestDatas.filter((el) => {
        if (el.id !== data.id) return el
        configUpdate = el
      })
    if (!lodash.isEqual(data, configUpdate)) setEditConfigs(true)
    updateListsPanel({ params :{ panelInvestDatas: [...configs, {...data}].sort((a,b) => a.id - b.id) }})
  }

  function symbolsUpdate(row, info) {
    let data
    if (updateSymbols.length) {
      data = updateSymbols.map((symbol) => {
        if (symbol.symbol === row.symbol) return { ...symbol, flgEnabled: info }
          return symbol
      })
    } else {
      data = listsPanel.find(({ id }) => activeList.id === id).panelInvestSymbols.map((symbol) => {
        if (symbol.symbol === row.symbol) return { ...symbol, flgEnabled: info }
          return symbol
      })
    }
    updateListsPanel({ params: {panelInvestSymbols: [ ...data ] }})
    setUpdateSymbols(data)
  }

  function setRestoreDefault() {
    restoreDefault()
    setUpdateSymbols([])
    setEditConfigs(true)
    setUpdateRenderItems((e) => !e)
  }

  useEffect(() => {
    setUpdateSymbols([])
    setEditConfigs(false)
    setUpdateRenderItems((e) => !e)
  }, [activeList])

  useEffect(() => {
    if (updateSymbols.length) setEditConfigs(true)
  }, [updateSymbols])

  return (
    listsPanel.length === 0
    ? <div className='flex justify-center items-center w-full h-[30vw]'>
      <LoadingBox />
    </div> 
    : <section className={`flex flex-col w-full ${mobile ? 'rounded-xl' : 'px-[30px] border-y-2 border-r-2 border-[#DBDBDB] border-l-0 rounded-e-xl'}`}>
      <div className={`flex ${mobile ? 'flex-wrap space-y-5' : 'justify-between'} pt-[30px] pb-5 border-b border-[#C5C5C5]`}>
        <div className='flex items-center'>
          {mobile && <MdArrowBackIos onClick={() => editConfigs ? use.setIsOpenNotification(true) : onChangeViewMobile(0)} className='text-primary cursor-pointer' size={24} />}
          {activeList.name && <span className='uppercase text-primary text-base font-semibold'>{`Monitoração - ${activeList.name}`}</span>}
        </div>
        <button onClick={() => setIsOpenModalActivesInPanel(true)} disabled={!listsPanel.find(({ id }) => activeList.id === id)?.flgEnabled} className='flex items-center btn border-2 border-primary text-primary space-x-3 cursor-pointer'>
          <MdOutlineManageSearch size={20} />
          <span className='font-semibold'>ativos no painel</span>
        </button>
      </div>
      <div className='grow flex flex-col justify-between'>
        <div className='pt-[30px] flex flex-col'>
          <span className='uppercase text-primary text-base font-semibold'>{`Configurações`}</span>
          <span className='mt-[30px] mb-[14px] text-primary text-xs uppercase'>Ativo</span>
          <div className='flex flex-col space-y-5'>
            {listsPanel.find(({ id }) => id === activeList.id)?.panelInvestDatas?.map((item) => {
              return (
                <Fragment key={`Config_Items_Alert_Monitor_${item.id}`}>
                  {activeList && 
                    <ConfigItem
                      row={item}
                      firstText={item.firstText}
                      lastText={item.lastText}
                      numberAbove={item.numberAbove}
                      numberBelow={item.numberBelow}
                      disabled={listsPanel.find(({ id }) => activeList.id === id).flgEnabled }
                      checked={item.flgEnabled}
                      setData={configsUpdate}
                      updateRender={updateRenderItems}
                    />}
                </Fragment>
              )
            } 
            )}
          </div>
        </div>
        <div className='flex flex-wrap space-y-4 min-[903px]:space-y-0 xl:space-x-32 items-center pt-3 min-[903px]:pt-[60px] pb-[30px]'>
          <span onClick={() => listsPanel.find(({ id }) => activeList.id === id)?.flgEnabled && setRestoreDefault()} disabled={!listsPanel.find(({ id }) => activeList.id === id)?.flgEnabled} className={`flex-none mr-4 font-semibold link underline uppercase ${listsPanel.find(({ id }) => activeList.id === id)?.flgEnabled ? 'cursor-pointer text-primary' : 'hover:text-grayDisabled text-grayDisabled cursor-not-allowed'}`}>restaurar padrão</span>
          <div className='flex-auto flex-nowrap justify-center'>
            <button onClick={() => cancelUpdate()} disabled={!listsPanel.find(({ id }) => activeList.id === id)?.flgEnabled} className={`btn uppercase mr-5 ${listsPanel.find(({ id }) => activeList.id === id)?.flgEnabled ? 'btn-secondary text-primary' : 'bg-[#EDEDED] text-grayDisabled'}`}>cancelar</button>
            <button onClick={() => updateActiveList()} disabled={!listsPanel.find(({ id }) => activeList.id === id)?.flgEnabled} className={`btn uppercase ${listsPanel.find(({ id }) => activeList.id === id)?.flgEnabled ? 'btn-primary text-secondary' : 'bg-[#EDEDED] text-grayDisabled'}`}>salvar</button>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isOpenModalActivesInPanel}
        onRequestClose={() => setIsOpenModalActivesInPanel(false)}
        style={
          {
            content:
            {
              maxWidth: mobile ? width : 411,
              maxHeight: mobile ? height : 631,
              margin: "auto",
              borderRadius: 12,
              marginRight: mobile ? -40 : "auto",
              marginLeft: mobile ? -40 : "auto",
              overflow: 'hidden',
              display: 'flex',
              flexDirection: 'column'
            }
          }
        }
      >
      <CloseButton onClick={() => setIsOpenModalActivesInPanel(false)} />
      <div className="mb-10">
        <h2 className="font-bold text-xl text-primary uppercase mb-2">Ativos no painel</h2>
        <p className={`text-dark-gray text-sm`}>Ativos que são monitorados e geram alertas neste painel.</p>
      </div>
      <div className={`grow mini-scrollbar overflow-y-auto border border-light-gray divide-y divide-light-gray`}>
        { updateSymbols.length 
          ? updateSymbols.map((item) => (
            item && <div key={`Alert_Monitor_List_Symbols${item.symbol}`} className='flex justify-between px-5 py-3'>
              <div className='flex flex-col text-dark-gray'>
                <span className='font-semibold'>{item.symbol}</span>
                <span>{item.name}</span>
              </div>
              <div className='flex items-center space-x-2 text-dark-gray'>
                <span className='text-xs'>{item.nameOrigin}</span>
                <ToggleCustom id={item.symbol} isChecked={item.flgEnabled} onChange={(e) => symbolsUpdate(item, e)} />
              </div>
            </div>))
          : activeList.panelInvestSymbols?.length 
            ? listsPanel.find(({ id }) => activeList.id === id)?.panelInvestSymbols.map((item) => (
              item && <div key={`Alert_Monitor_List_Symbols${item.symbol}`} className='flex justify-between px-5 py-3'>
                <div className='flex flex-col text-dark-gray'>
                  <span className='font-semibold'>{item.symbol}</span>
                  <span>{item?.name}</span>
                </div>
                <div className='flex items-center space-x-2 text-dark-gray'>
                  <span className='text-xs text-right'>{item?.nameOrigin}</span>
                  <ToggleCustom id={item.symbol} isChecked={item.flgEnabled} onChange={(e) => symbolsUpdate(item, e)} />
                </div>
              </div>
            )) 
            : <span className='p-2 text-grayDisabled'>Nenhum ativo adicionado no painel.</span>
        }
      </div>
      </Modal>
      <NotificationModal
        isOpen={use.isOpenNotification}
        closeModal={() => closeModal()}
        title={`Deseja salvar as modificações?`}
        text='Você alterou as configurações desta lista, e perderá as alterações se não forem salvas.'
        style={{ 
          content: {
            maxWidth: 430,
            maxHeight: width < 500 ? 360 : 270,
            margin: "auto", 
            marginRight: mobile ? -40 : "auto",
            marginLeft: mobile ? -40 : "auto",
            borderRadius: 10
          }
        }}
        successLabel="Salvar"
        closeLabel="Descartar"  
        successModal={() => updateActiveList()}
        actionButtons 
      />
    </section>
  )
}

function ConfigItem({ row, firstText, lastText, numberAbove, numberBelow, disabled = true, checked, updateRender, setData = () => {}}) {
  const [edit, setEdit] = useState(false)
  const [isChecked, setIsChecked] = useState(checked)
  const [isNumberAbove, setIsNumberAbove] = useState(numberAbove)
  const [isNumberBelow, setIsNumberBelow] = useState(numberBelow)

  const regexInput = /^[+-]?\d*$|^$/

  const editing = () => {
    if(!disabled || !isChecked) {
      setEdit(false)
    } else setEdit(e => !e)
  }

  useEffect(() => {
    if (disabled || !isChecked) setEdit(false)
  }, [disabled, isChecked])

  useEffect(() => {
    setIsChecked(checked)
    setIsNumberAbove(numberAbove)
    setIsNumberBelow(numberBelow)
  }, [numberAbove, numberBelow, checked])

  useEffect(() => {
    setData({ ...row, flgEnabled: isChecked, numberAbove: isNumberAbove, numberBelow: isNumberBelow })
  }, [isNumberAbove, isNumberBelow, isChecked])

  useEffect(() => {
    setEdit(false)
  }, [updateRender])

  return (
    <div className='flex space-x-3'>
      <CheckboxBasic setIsChecked={(e) => setIsChecked(e) } isChecked={isChecked} disabled={!disabled} style={{background:  (!disabled) && 'white' }} styleChecked={`${ (!disabled) && 'color-disabled' }`} />
      <span className={`flex-wrap flex items-center space-x-1 text-base font-semibold ${!disabled || !isChecked ? 'text-grayDisabled' : 'text-dark-gray'}`}>
        <span>{firstText}</span>
        {edit 
          ? <input
              id={`input_number_below_${Math.random() * 100}`}
              onChange={({ target }) => (target.value === '' || regexInput.test(target.value)) && setIsNumberBelow(target.value)}
              onBlur={() => isNumberBelow === '' && setIsNumberBelow(0)}
              value={isNumberBelow}
              type="text"
              className="input w-20 px-3 py-2 rounded-xl text-primary"
              autoFocus
            />
          : <span className={`${(disabled && isChecked) && 'text-primary'} font-bold`}>{`${isNumberBelow}%`}</span>
        }
        <span>{lastText}</span>
        {edit 
          ? <input
              id={`input_number_above_${Math.random() * 100}`}
              onChange={({ target }) => (target.value === '' || regexInput.test(target.value)) && setIsNumberAbove(target.value)}
              onBlur={() => isNumberAbove === '' && setIsNumberAbove(0)}
              value={isNumberAbove}
              type="text"
              className="input w-20 px-3 py-2 rounded-xl text-primary"
              autoFocus
            />
          : <span className={`${(disabled && isChecked) && 'text-primary'} font-bold`}>{`${isNumberAbove}%`}</span>
        }
      </span>
      <MdOutlineModeEditOutline id='TooltipEditItemConfig' className={`flex-none outline-none ${disabled && isChecked ? 'text-primary cursor-pointer' : 'text-grayDisabled'}`} onClick={() => editing()} />
      <SimpleTooltip anchor={`#TooltipEditItemConfig`} value={'Editar'} />
    </div>
  )
}



export { AlertMonitor }
