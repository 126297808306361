import { useState } from "react"
import { MdAdd } from 'react-icons/md'

import useWindowSize from 'hooks/useWindowSize'

import ModalAddModules from "../modals/ModalAddModules"

function AddModules({ onAddItems }) {

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const { width } = useWindowSize()
  const [isHovered, setIsHovered] = useState(false)

  const openModal = () => {
    setModalIsOpen(true)
  }
  const closeModal = () => setModalIsOpen(false)
  const submitData = (data) => {
    onAddItems(data)
    closeModal()
  }

  return (
    <>
      <button
        style={{ width: 330 }}
        className="bg-primary h-full py-4 px-8 flex items-center rounded-xl space-x-4 transition hover:brightness-90 justify-between focus-visible:outline-primaryFaded"
        onClick={openModal}
        onMouseOver={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <h6 className="text-secondary font-semibold uppercase">Adicione Módulo(s)</h6>
        <span className={`rounded-full bg-secondary ${width <= 350 ? 'h-[38px] w-[48px]' : ''} ${width < 402 ? 'h-[38px] w-[42px] text-2xl' : ''} h-12 w-12 flex items-center justify-center cursor-pointer hover:scale-110 transition-all text-3xl text-primary ${isHovered ? 'scale-110' : ''}`}>
          <MdAdd />
        </span>
      </button>
      <ModalAddModules modalIsOpen={modalIsOpen} closeModal={closeModal} submitData={submitData} />
    </>
  )
}

export default AddModules
