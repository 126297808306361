import { useDynamicWindowSize } from "hooks/useWindowSize"
import useMenu from "hooks/useMenu"
import IcoSandwich from "assets/icons/menu/ico-menu-mobile-sandwich.svg"
import IcoSearch from "assets/icons/menu/ico-menu-mobile-search.svg"
import IcoHome from "assets/icons/menu/ico-menu-mobile-home.svg"
import IcoAlert from "assets/icons/menu/ico-menu-mobile-alert.svg"
import { useNavigate } from "react-router-dom"
import { useContext, useState } from "react"
import { LAYOUT_BREAKPOINT_WIDTH } from "const"
import AccessInterceptorContext from "context/AccessInterceptorContext"
import { MdOutlineDiamond } from "react-icons/md"
import SearchModal from "shared/modal/SearchModal"

function BottomNav() {
  const { width } = useDynamicWindowSize()

  const navigate = useNavigate()
  const [isSidebarVisible, setIsSidebarVisible] = useState(false)
  const [isSearchModalVisible, setIsSearchModalVisible] = useState(false)

  if (width >= LAYOUT_BREAKPOINT_WIDTH - 10)
    return null

  const menuItems = [
    { ico: IcoSandwich, name: 'Menu', onClick: () => setIsSidebarVisible(true) },
    { ico: IcoHome, name: 'Home', onClick: () => navigate('/') },
    { ico: IcoAlert, name: 'Alert', onClick: () => navigate('/alerts') },
    { ico: IcoSearch, name: 'Search', onClick: () => setIsSearchModalVisible(true) },
  ]

  return (
    <>
      <nav className="shadow bg-white w-full flex justify-evenly items-center overflow-hidden h-13 min-h-13 border-t">
        {menuItems.map(item => (
          <button
            key={'bottom-nav-item-' + item.name}
            className="py-4 px-6 rounded-md hover:bg-gray-100"
            onClick={(e) => item.onClick()}
          >
            <img src={item.ico} alt={item.name} />
          </button>
        ))}
      </nav>
      {isSidebarVisible && <Sidebar dismiss={() => setIsSidebarVisible(false)} />}
      {isSearchModalVisible && <SearchModal isOpen={isSearchModalVisible} closeModal={() => setIsSearchModalVisible(false)} />}
    </>
  )
}

function Sidebar({ dismiss }) {
  const { menuItems } = useMenu()
  const navigate = useNavigate()
  const { setIsAccessBlocked } = useContext(AccessInterceptorContext)

  const selectSubitem = (subitem) => {
    dismiss()
    if (subitem.restricted)
      return setIsAccessBlocked(true)

    setTimeout(() => {
      if (subitem.target === '_blank') {
        window.open(subitem.url, subitem.target)
      } else if (subitem.url) {
        navigate(subitem.url)
      } else if (typeof subitem.fn === 'function') {
        subitem.fn()
      }
    }, 200)
  }

  return (
    <>
      <div className="w-full h-full bg-black bg-opacity-30 fixed z-40 top-0" onClick={dismiss}></div>
      <aside className="bg-white shadow-md fixed h-full w-[250px] z-50 top-0 p-3 overflow-y-auto">
        {
          menuItems.map(item => {
            if (!item.subitems) return null
            return (
              <div key={'responsive-menu-item-' + item.name} >
                <h6 className="text-primary uppercase text-base font-bold mb-2">{item.name}</h6>
                <ul className="mb-4">
                  {
                    item.subitems.map((subitem, index) => (
                      <li key={`responsive-menu-item-${item.name}-${subitem.name}`} className="cursor-pointer flex items-center hover:bg-gray-100 space-x-2 py-2" onClick={() => selectSubitem(subitem)}>
                        <div
                          className={`
                            rounded-full 
                            h-[35px] w-[35px] min-w-[35px]
                            cursor-pointer
                            transition
                            ease-in
                            flex
                            justify-center
                            items-center
                            ${subitem.type === 'workspace' ? (subitem.isMain ? 'text-secondary bg-primary' : 'text-primary border-2 border-primary') : 'text-primary bg-secondary'}
                          `}>
                          {subitem.ico && (subitem.ico.includes('.svg') && <img src={subitem.ico} alt={subitem.name} className="w-4 h-4" />)}
                          {subitem.url?.includes('modulo') && <div className="material-icons">{subitem.ico}</div>}
                          {subitem.md && <div className="material-icons text-xl">{subitem.md}</div>}
                        </div>
                        <span className="text-primary uppercase text-xs font-bold">{subitem.name}</span>
                        {subitem.restricted && <MdOutlineDiamond className="text-terciary inline text-lg w-4" />}
                      </li>
                    ))
                  }
                </ul>
              </div>
            )
          })
        }
      </aside>
    </>
  )

}

export default BottomNav