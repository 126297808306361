import { useEffect, useMemo, useState } from "react"
import PreferencesApi from "api/PreferencesApi"
import { toast } from "react-toastify"

function usePanelQuotationLists() {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(true)

  useEffect(() => {
    const fetchCompanies = async () => {
      setLoading(true)

      try {
        const data = await PreferencesApi.getPanelQuotationList()
        setError(null)
        for (const item of data) {
          item.symbols.forEach(x => x.id = `${x.origin}_${x.symbol}`)
        }
        setData(data)
      } catch (error) {
        console.error(error)
        toast.error('Falha ao buscar empresas!')
        setError(error)
        setData(null)
      } finally {
        setLoading(false)
      }
    }

    fetchCompanies()
  }, [])

  const result = useMemo(() => ({ data, loading, error }), [data, loading, error])

  return result

}

export default usePanelQuotationLists